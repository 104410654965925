// ------------------------------------
// utilities to resolve and manipulate assets urls
// ------------------------------------
/// @access private
// ------------------------------------

@import '../config/all';
@import 'string'; // requires str-replace function

// ------------------------------------
// asset-url function
// ------------------------------------
// helper to create valid assets url for devportal
//
/// @param {String} $type - Asset type, matching folder name
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the asset
// ------------------------------------

@function asset-url($type, $file) {
  @return url($asset-base-path + '/' + $type + '/' + $file);
}



// ------------------------------------
// image-url funtion
// ------------------------------------
// helper to create valid assets url for devportal images
//
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the image
/// @require {function} asset
// ------------------------------------

@function image-url($file) {
  @return asset-url('img', $file);
}



// ------------------------------------
// svg-url-encode function
// ------------------------------------
// function to url encode a given svg (urls)
//
/// @param {String} $svg - Url of the svg to be encoded
/// @return {URL} - A valid urlencoded version of the svg
// ------------------------------------

@function svg-url-encode($svg) {
  //
  //  Add missing namespace
  //
  @if not str-index($svg, xmlns) {
    $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
  }
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '%', '%25');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $chunk: str-replace($chunk, '{', '%7B');
    $chunk: str-replace($chunk, '}', '%7D');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  // sass-lint:disable quotes
  // double quotes are necessary for IE/FF compatibility when creating a URI incoded version of the SVG
  @return url("data:image/svg+xml,#{$encoded}");
}
