// ----------------------------------------------
// Bullet list element
// ----------------------------------------------
// .[prefix-]list
// ------------------------------------

@import '../config/all';
@import '../utils/all';


#{$config-class-prefix} {
  // Bullet list
  // ----------------------------------------------

  // @todo here and everywhere where `.flix-text` typography styles are present -> make this more elegant and move them to a separate place
  &list,
  &text ul {
    margin: 0 0 get-spacing(xs);
    padding-left: get-spacing(xs);
    list-style-type: none;
  }

  // nested lists
  &list &list,
  &text ul ul {
    margin: 0; // removes bottom margins for nested lists
    padding-left: 0;
  }

  &list__item,
  &text ul li {
    position: relative;
    padding-left: get-spacing(xs) + 4;
    line-height: $line-height-primary;

    &::before {
      display: block;
      position: absolute;
      top: $line-height-primary / 2; // ensure it's positioned in in the middle of the text vertically
      left: 0;
      width: 4px;
      height: 4px;
      margin-top: -2px; // half of the size
      margin-right: get-spacing(xs);
      border-radius: 50%;
      background: $primary-content-color;
      content: '';
    }
  }
}
