// ----------------------------------------------
// Image link (.[prefix-]img-link)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$img-link-bg-color: $primary-bg-color;
$img-link-border-radius: $primary-border-radius;
$img-link-border-color: $primary-line-color;
$img-link-icon-color: $primary-icon-color;
$img-link-box-shadow: $primary-box-shadow;
$img-link-image-height: 200px;
$img-link-image-height-mobile: 160px;
$img-link-sm-image-height: 90px;

#{$config-class-prefix} {
  &img-link {
    display: flex;
    flex-wrap: wrap; // bigger version has full width image and content areas
    margin-bottom: get-spacing(xs);
    border: 1px solid $img-link-border-color;
    border-radius: $img-link-border-radius;
    background: $img-link-bg-color;
    text-decoration: none;

    &:hover {
      box-shadow: $img-link-box-shadow;
    }

    &__img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: $img-link-image-height;
      border-radius: $img-link-border-radius $img-link-border-radius 0 0;
      overflow: hidden;

      @include on-bp(xl) {
        height: $img-link-image-height-mobile;
      }
    }

    &__img {
      display: block;
      width: 100%;
    }

    // adds proper image resizing for those browsers that support object-fit
    @supports (object-fit: cover) {
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      display: block;
      position: relative;
      width: 100%;
      padding: get-spacing(xs) get-spacing(sm) get-spacing(xs) get-spacing(xs);
      border-top: 1px solid $img-link-border-color;
      border-radius: 0 0 $img-link-border-radius $img-link-border-radius;

      // arrow in content area
      &::after {
        @include flix-icon-abstract;
        @include flix-icon;
        @include flix-icon-arrow-right;
        position: absolute;
        top: 50%;
        right: get-spacing(xs);
        margin-top: -11px;
        color: $img-link-icon-color;
      }
    }

    // smaller version with a square image on the side
    &--sm {
      flex-wrap: nowrap;
      height: $img-link-sm-image-height + 2; // compensating borders

      #{$config-class-prefix}img-link__img-wrapper {
        width: $img-link-sm-image-height;
        height: $img-link-sm-image-height;
        border-radius: $img-link-border-radius 0 0 $img-link-border-radius;
      }

      #{$config-class-prefix}img-link__content {
        width: calc(100% - #{$img-link-sm-image-height});
        padding: get-spacing(xs) get-spacing(md) get-spacing(xs) get-spacing(sm);
        border-top: 0;
        border-left: 1px solid $img-link-border-color;

        // arrow in content area
        &::after {
          right: get-spacing(sm);
        }
      }
    }
  }
}
