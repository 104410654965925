// ----------------------------------------------
// Textarea (.[prefix-]textarea)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$textarea-vertical-padding-mobile: 10px;
$textarea-vertical-padding: 5px;
$textarea-horizontal-padding: get-spacing(xs);


#{$config-class-prefix} {

  &textarea {

    @include font-primary-normal;
    position: relative;
    width: 100%;
    // roughly mades the textarea height the size of 2 text input elements
    // 30px (sm spacing) is the control bottom margin we need to include as well
    //@todo figure out the better way of defining textarea's height
    min-height: $input-height-mobile * 2 + get-spacing(sm);
    padding: $textarea-vertical-padding-mobile $textarea-horizontal-padding;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    background-color: $input-bg-color;
    color: $primary-content-color;
    line-height: $line-height-primary;
    // Remove the default vertical scrollbar in IE 10+
    overflow: auto;
    //removes spacing underneath the textarea, the inline-block issue
    vertical-align: top;
    appearance: none;

    @include on-bp(xl) {
      // 24px is the label height, 15px is the control bottom margin we need to include as well
      min-height: $input-height-desktop * 2 + 15px + 24px;
      padding: $textarea-vertical-padding $textarea-horizontal-padding;
    }

    &::placeholder {
      color: $secondary-content-color;
      font-style: italic;
    }

    &:focus,
    &--active {
      border: 1px solid $input-active-border-color;
      outline: none;
      color: $primary-content-color;
    }

    &[disabled],
    &--disabled {
      opacity: .5;
    }

    &--valid {
      padding-right: get-spacing(sm);
      @include input-icon-valid($position: right 15px top 10px);
    }

    &--error {
      padding-right: get-spacing(sm);
      @include input-icon-error($position: right 15px top 8px);
      border: 1px solid $input-error-border-color;
    }
  }
}
