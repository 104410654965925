// ------------------------------------
// Text alignment helpers
// ------------------------------------

@import '../utils/all';

#{$config-class-prefix} {

  // applies different types of text alignment

  &has-text-centered {
    text-align: center;
  }

  &has-text-left {
    text-align: left;
  }

  &has-text-right {
    text-align: right;
  }
}
