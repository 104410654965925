// ------------------------------------
// NOTE: this is an autogenerated file
// Refer to fontello:build script in './scripts/fontello'
// ------------------------------------

@mixin flix-icon-account-attention-solid {
  content: '\e800';
}

@mixin flix-icon-account-attention {
  content: '\e801';
}

@mixin flix-icon-account-settings-solid {
  content: '\e802';
}

@mixin flix-icon-account-settings {
  content: '\e803';
}

@mixin flix-icon-account-solid {
  content: '\e804';
}

@mixin flix-icon-account {
  content: '\e805';
}

@mixin flix-icon-airport-solid {
  content: '\e806';
}

@mixin flix-icon-airport {
  content: '\e807';
}

@mixin flix-icon-arrow-down {
  content: '\e808';
}

@mixin flix-icon-arrow-left {
  content: '\e809';
}

@mixin flix-icon-arrow-right {
  content: '\e80a';
}

@mixin flix-icon-arrow-up {
  content: '\e80b';
}

@mixin flix-icon-attention-solid {
  content: '\e80c';
}

@mixin flix-icon-attention {
  content: '\e80d';
}

@mixin flix-icon-audio-solid {
  content: '\e80e';
}

@mixin flix-icon-audio {
  content: '\e80f';
}

@mixin flix-icon-beach-solid {
  content: '\e810';
}

@mixin flix-icon-beach {
  content: '\e811';
}

@mixin flix-icon-beer {
  content: '\e812';
}

@mixin flix-icon-bike-solid {
  content: '\e813';
}

@mixin flix-icon-bike {
  content: '\e814';
}

@mixin flix-icon-birthday-solid {
  content: '\e815';
}

@mixin flix-icon-birthday {
  content: '\e816';
}

@mixin flix-icon-book-solid {
  content: '\e817';
}

@mixin flix-icon-book {
  content: '\e818';
}

@mixin flix-icon-booster-solid {
  content: '\e819';
}

@mixin flix-icon-booster {
  content: '\e81a';
}

@mixin flix-icon-burger {
  content: '\e81b';
}

@mixin flix-icon-bus-night-solid {
  content: '\e81c';
}

@mixin flix-icon-bus-night {
  content: '\e81d';
}

@mixin flix-icon-bus-service-solid {
  content: '\e81e';
}

@mixin flix-icon-bus-service {
  content: '\e81f';
}

@mixin flix-icon-bus-solid {
  content: '\e820';
}

@mixin flix-icon-bus-stop-solid {
  content: '\e821';
}

@mixin flix-icon-bus-stop {
  content: '\e822';
}

@mixin flix-icon-bus-time-solid {
  content: '\e823';
}

@mixin flix-icon-bus-time {
  content: '\e824';
}

@mixin flix-icon-bus {
  content: '\e825';
}

@mixin flix-icon-buses {
  content: '\e826';
}

@mixin flix-icon-calendar-solid {
  content: '\e827';
}

@mixin flix-icon-calendar {
  content: '\e828';
}

@mixin flix-icon-cart-solid {
  content: '\e829';
}

@mixin flix-icon-cart {
  content: '\e82a';
}

@mixin flix-icon-chat-solid {
  content: '\e82b';
}

@mixin flix-icon-chat {
  content: '\e82c';
}

@mixin flix-icon-check-solid {
  content: '\e82d';
}

@mixin flix-icon-check {
  content: '\e82e';
}

@mixin flix-icon-checkmark-strong-solid {
  content: '\e82f';
}

@mixin flix-icon-checkmark-strong {
  content: '\e830';
}

@mixin flix-icon-checkmark {
  content: '\e831';
}

@mixin flix-icon-city-solid {
  content: '\e832';
}

@mixin flix-icon-city {
  content: '\e833';
}

@mixin flix-icon-close {
  content: '\e834';
}

@mixin flix-icon-collapse {
  content: '\e835';
}

@mixin flix-icon-connection-transfer {
  content: '\e836';
}

@mixin flix-icon-connection {
  content: '\e837';
}

@mixin flix-icon-credit-card-solid {
  content: '\e838';
}

@mixin flix-icon-credit-card {
  content: '\e839';
}

@mixin flix-icon-customer-solid {
  content: '\e83a';
}

@mixin flix-icon-customer {
  content: '\e83b';
}

@mixin flix-icon-delete-solid {
  content: '\e83c';
}

@mixin flix-icon-delete {
  content: '\e83d';
}

@mixin flix-icon-directions-solid {
  content: '\e83e';
}

@mixin flix-icon-directions {
  content: '\e83f';
}

@mixin flix-icon-discount-solid {
  content: '\e840';
}

@mixin flix-icon-discount {
  content: '\e841';
}

@mixin flix-icon-drinks-solid {
  content: '\e842';
}

@mixin flix-icon-drinks {
  content: '\e843';
}

@mixin flix-icon-driver-solid {
  content: '\e844';
}

@mixin flix-icon-driver {
  content: '\e845';
}

@mixin flix-icon-ebus-solid {
  content: '\e846';
}

@mixin flix-icon-ebus {
  content: '\e847';
}

@mixin flix-icon-edit-solid {
  content: '\e848';
}

@mixin flix-icon-edit {
  content: '\e849';
}

@mixin flix-icon-entertainment-solid {
  content: '\e84a';
}

@mixin flix-icon-entertainment {
  content: '\e84b';
}

@mixin flix-icon-expand {
  content: '\e84c';
}

@mixin flix-icon-filter {
  content: '\e84d';
}

@mixin flix-icon-full-screen {
  content: '\e84e';
}

@mixin flix-icon-games-solid {
  content: '\e84f';
}

@mixin flix-icon-games {
  content: '\e850';
}

@mixin flix-icon-group-solid {
  content: '\e851';
}

@mixin flix-icon-group {
  content: '\e852';
}

@mixin flix-icon-headphones-solid {
  content: '\e853';
}

@mixin flix-icon-headphones {
  content: '\e854';
}

@mixin flix-icon-home-solid {
  content: '\e855';
}

@mixin flix-icon-home {
  content: '\e856';
}

@mixin flix-icon-info-solid {
  content: '\e857';
}

@mixin flix-icon-info {
  content: '\e858';
}

@mixin flix-icon-invoice {
  content: '\e859';
}

@mixin flix-icon-label-attention-solid {
  content: '\e85a';
}

@mixin flix-icon-label-attention {
  content: '\e85b';
}

@mixin flix-icon-label-solid {
  content: '\e85c';
}

@mixin flix-icon-label {
  content: '\e85d';
}

@mixin flix-icon-leaf-solid {
  content: '\e85e';
}

@mixin flix-icon-leaf {
  content: '\e85f';
}

@mixin flix-icon-link-solid {
  content: '\e860';
}

@mixin flix-icon-link {
  content: '\e861';
}

@mixin flix-icon-location-solid {
  content: '\e862';
}

@mixin flix-icon-location {
  content: '\e863';
}

@mixin flix-icon-lost-solid {
  content: '\e864';
}

@mixin flix-icon-lost {
  content: '\e865';
}

@mixin flix-icon-luggage-additional-solid {
  content: '\e866';
}

@mixin flix-icon-luggage-additional {
  content: '\e867';
}

@mixin flix-icon-luggage-bulky-solid {
  content: '\e868';
}

@mixin flix-icon-luggage-bulky {
  content: '\e869';
}

@mixin flix-icon-luggage-hand-solid {
  content: '\e86a';
}

@mixin flix-icon-luggage-hand {
  content: '\e86b';
}

@mixin flix-icon-luggage-solid {
  content: '\e86c';
}

@mixin flix-icon-luggage {
  content: '\e86d';
}

@mixin flix-icon-magnifier-solid {
  content: '\e86e';
}

@mixin flix-icon-magnifier {
  content: '\e86f';
}

@mixin flix-icon-map-solid {
  content: '\e870';
}

@mixin flix-icon-map {
  content: '\e871';
}

@mixin flix-icon-men-women-solid {
  content: '\e872';
}

@mixin flix-icon-men-women {
  content: '\e873';
}

@mixin flix-icon-minus {
  content: '\e874';
}

@mixin flix-icon-mountains-solid {
  content: '\e875';
}

@mixin flix-icon-mountains {
  content: '\e876';
}

@mixin flix-icon-newsletter-solid {
  content: '\e877';
}

@mixin flix-icon-newsletter {
  content: '\e878';
}

@mixin flix-icon-night-solid {
  content: '\e879';
}

@mixin flix-icon-night {
  content: '\e87a';
}

@mixin flix-icon-passport-solid {
  content: '\e87b';
}

@mixin flix-icon-passport {
  content: '\e87c';
}

@mixin flix-icon-person-solid {
  content: '\e87d';
}

@mixin flix-icon-person {
  content: '\e87e';
}

@mixin flix-icon-phone-solid {
  content: '\e87f';
}

@mixin flix-icon-phone-tablet-solid {
  content: '\e880';
}

@mixin flix-icon-phone-tablet {
  content: '\e881';
}

@mixin flix-icon-phone {
  content: '\e882';
}

@mixin flix-icon-pin-solid {
  content: '\e883';
}

@mixin flix-icon-pin {
  content: '\e884';
}

@mixin flix-icon-plane-solid {
  content: '\e885';
}

@mixin flix-icon-plane {
  content: '\e886';
}

@mixin flix-icon-plus {
  content: '\e887';
}

@mixin flix-icon-q-and-a-solid {
  content: '\e888';
}

@mixin flix-icon-q-and-a {
  content: '\e889';
}

@mixin flix-icon-question-solid {
  content: '\e88a';
}

@mixin flix-icon-question {
  content: '\e88b';
}

@mixin flix-icon-real-time-solid {
  content: '\e88c';
}

@mixin flix-icon-real-time {
  content: '\e88d';
}

@mixin flix-icon-rebook-solid {
  content: '\e88e';
}

@mixin flix-icon-rebook {
  content: '\e88f';
}

@mixin flix-icon-refresh {
  content: '\e890';
}

@mixin flix-icon-seat-belt-solid {
  content: '\e891';
}

@mixin flix-icon-seat-belt {
  content: '\e892';
}

@mixin flix-icon-seat-solid {
  content: '\e893';
}

@mixin flix-icon-seat {
  content: '\e894';
}

@mixin flix-icon-secure-payment-solid {
  content: '\e895';
}

@mixin flix-icon-secure-payment {
  content: '\e896';
}

@mixin flix-icon-security-solid {
  content: '\e897';
}

@mixin flix-icon-security {
  content: '\e898';
}

@mixin flix-icon-settings-solid {
  content: '\e899';
}

@mixin flix-icon-settings {
  content: '\e89a';
}

@mixin flix-icon-sit-solid {
  content: '\e89b';
}

@mixin flix-icon-sit {
  content: '\e89c';
}

@mixin flix-icon-snacks-solid {
  content: '\e89d';
}

@mixin flix-icon-snacks {
  content: '\e89e';
}

@mixin flix-icon-socket-solid {
  content: '\e89f';
}

@mixin flix-icon-socket {
  content: '\e8a0';
}

@mixin flix-icon-stopwatch-solid {
  content: '\e8a1';
}

@mixin flix-icon-stopwatch {
  content: '\e8a2';
}

@mixin flix-icon-stroller-solid {
  content: '\e8a3';
}

@mixin flix-icon-stroller {
  content: '\e8a4';
}

@mixin flix-icon-suitcase-solid {
  content: '\e8a5';
}

@mixin flix-icon-suitcase {
  content: '\e8a6';
}

@mixin flix-icon-switch-vertical {
  content: '\e8a7';
}

@mixin flix-icon-switch {
  content: '\e8a8';
}

@mixin flix-icon-ticket-solid {
  content: '\e8a9';
}

@mixin flix-icon-ticket-special-solid {
  content: '\e8aa';
}

@mixin flix-icon-ticket-special {
  content: '\e8ab';
}

@mixin flix-icon-ticket {
  content: '\e8ac';
}

@mixin flix-icon-time-solid {
  content: '\e8ad';
}

@mixin flix-icon-time {
  content: '\e8ae';
}

@mixin flix-icon-toilet-solid {
  content: '\e8af';
}

@mixin flix-icon-toilet {
  content: '\e8b0';
}

@mixin flix-icon-train-night-solid {
  content: '\e8b1';
}

@mixin flix-icon-train-night {
  content: '\e8b2';
}

@mixin flix-icon-train-service-solid {
  content: '\e8b3';
}

@mixin flix-icon-train-service {
  content: '\e8b4';
}

@mixin flix-icon-train-solid {
  content: '\e8b5';
}

@mixin flix-icon-train-stop-solid {
  content: '\e8b6';
}

@mixin flix-icon-train-stop {
  content: '\e8b7';
}

@mixin flix-icon-train-time-solid {
  content: '\e8b8';
}

@mixin flix-icon-train-time {
  content: '\e8b9';
}

@mixin flix-icon-train-wagon-solid {
  content: '\e8ba';
}

@mixin flix-icon-train {
  content: '\e8bb';
}

@mixin flix-icon-transfer-solid {
  content: '\e8bc';
}

@mixin flix-icon-transfer {
  content: '\e8bd';
}

@mixin flix-icon-trip-solid {
  content: '\e8be';
}

@mixin flix-icon-trip {
  content: '\e8bf';
}

@mixin flix-icon-video-solid {
  content: '\e8c0';
}

@mixin flix-icon-video {
  content: '\e8c1';
}

@mixin flix-icon-voucher-solid {
  content: '\e8c2';
}

@mixin flix-icon-voucher {
  content: '\e8c3';
}

@mixin flix-icon-warning {
  content: '\e8c4';
}

@mixin flix-icon-wheelchair-solid {
  content: '\e8c5';
}

@mixin flix-icon-wheelchair {
  content: '\e8c6';
}

@mixin flix-icon-wifi-solid {
  content: '\e8c7';
}

@mixin flix-icon-wifi {
  content: '\e8c8';
}

@mixin flix-icon-arrow-big-down {
  content: '\e8c9';
}

@mixin flix-icon-arrow-big-left {
  content: '\e8ca';
}

@mixin flix-icon-arrow-big-right {
  content: '\e8cb';
}

@mixin flix-icon-arrow-big-up {
  content: '\e8cc';
}

@mixin flix-icon-car-solid {
  content: '\e8cd';
}

@mixin flix-icon-car {
  content: '\e8ce';
}
