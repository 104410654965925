
// ----------------------------------------------
// accordion (.[prefix-]accordion)
// ----------------------------------------------


@import '../config/all';
@import '../utils/all';
// base icon mixins to get arrow icons
@import '../icons/base-mixins';
@import '../icons/icon-mixins';


#{$config-class-prefix} {
  &accordion {
    display: block;
    padding: 0 get-spacing(xs);
    border-top: 1px solid $primary-line-color;
    border-bottom: 1px solid $primary-line-color;

    &__title {
      // sass-lint:disable no-vendor-prefixes
      display: block;
      position: relative;
      padding: get-spacing(xs) 0;
      outline: 0;
      font-weight: $font-weight-bold;
      cursor: pointer;

      // removing webkit's default arrow marker
      &::-webkit-details-marker {
        display: none;
      }

      &::after {
        @include flix-icon-abstract;
        @include flix-icon;
        @include flix-icon-arrow-down;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -11px;
      }
    }

    // "open" attribute is used in native <details>/<summary> HTML implementation
    // adding an "--open" modifier class as well for customizations and 3rd party scripts
    &[open],
    &--open {
      #{$config-class-prefix}accordion__title::after {
        @include flix-icon-arrow-up;
      }

      #{$config-class-prefix}accordion__content {
        display: block;
      }
    }

    &__content {
      display: none;
      padding-bottom: get-spacing(xs);
    }

    & + & {
      border-top: 0;
    }
  }
}
