// ------------------------------------
// Fieldset
// ------------------------------------
// .[prefix-]fieldset
// .[prefix-]legend
// ------------------------------------

@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &fieldset {
    margin: 0;
    padding: 0;
    border: 0;

    #{$config-class-prefix}fieldset__item {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  &legend {
    display: block;
    padding-bottom: 2px;
    color: $secondary-content-color;
    @include font-primary-normal;
  }
}
