// ------------------------------------
// Headers (.[prefix-]h#)
// ------------------------------------

@import '../config/all';
@import '../utils/all';

@include exports('heading') {
  %headline {
    color: $header-font-color;
    font-weight: $font-weight-bold;
  }

  #{$config-class-prefix} {
    &h1 {
      @extend %headline;
      margin: 0;
      padding: 0 0 get-spacing(sm);
      font-size: 28px;
      line-height: 34px;

      &--section-header {
        @extend %section-headline;
      }
    }

    &h2 {
      @extend %headline;
      margin: 0;
      padding: get-spacing(xs) 0;
      font-size: 22px;
      line-height: 28px;

      &--section-header {
        @extend %section-headline;
      }
    }

    &h3 {
      @extend %headline;
      margin: 0;
      padding: get-spacing(xs) 0;
      font-size: 18px;
      line-height: 24px;

      &--section-header {
        @extend %section-headline;
      }
    }

    &h4 {
      @extend %headline;
      margin: 0;
      padding: get-spacing(xs) 0;
      font-size: 16px;
      line-height: 22px;

      &--section-header {
        @extend %section-headline;
      }
    }
  }

  // headline placeholder needs to be here to overwrite the normal headline styles
  // related to the way SASS compiles placeholder styles when @extend is used
  %section-headline {
    padding-top: 0;
  }
}
