// ----------------------------------------------
// Tooltip (.[prefix-]toooltip)
// ----------------------------------------------


@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$tooltip-bg-color: $primary-bg-color;
$tooltip-error-bg-color: $fail-color;
$tooltip-font-size: $font-size-primary;
$tooltip-font-color: $primary-content-color;
$tooltip-font-weight: $font-weight-semibold;
$tooltip-error-font-color: $primary-bg-color;
$tooltip-border: $silver-grey-color;
$tooltip-box-shadow: $primary-box-shadow;
$tooltip-arrow-shadow-color: rgba(0, 0, 0, .1); // a lighter shadow makes it better
$tooltip-border-radius: $primary-border-radius;


// ----------------------------------------------
// Tooltip content block general styles
// Shared for both hover and rich text tooltips
// ----------------------------------------------

@mixin tooltip-content {
  position: absolute;
  top: 50%;
  left: 100%;
  width: 250px;
  min-height: 44px;
  margin-left: get-spacing(xs);
  padding: get-spacing(xs);
  transform: translateY(-50%);
  border-radius: $tooltip-border-radius;
  background: $tooltip-bg-color;
  color: $tooltip-font-color;
  font-family: $font-family-primary;
  font-size: $tooltip-font-size;
  text-align: center;
  text-decoration: none; // prevents underlined text for tooltips assigned to a link
  box-shadow: $tooltip-box-shadow;
  z-index: z(dropdown);
}

// Tooltip arrow general styles
// Shared for both hover and rich text tooltips
@mixin tooltip-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  width: 15px;
  height: 15px;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  background: $tooltip-bg-color;
  box-shadow: -3px 3px 5px $tooltip-arrow-shadow-color;
  z-index: z(dropdown);
}


@include exports('tooltip') {
  #{$config-class-prefix} {

    // Rich text tooltip styles
    // ----------------------------------------------

    &tooltip {
      @include tooltip-content();
      display: none;

      &::before {
        @include tooltip-arrow();
        content: '';
      }

      &--active {
        display: flex;
        text-align: left;
      }

      // @todo sort out and simplify positioning styles and optimize them
      // Makes tooltip to appear to the left
      &--left {
        right: 100%;
        left: auto;
        margin-right: get-spacing(xs);
        margin-left: 0;

        &::before {
          right: 0;
          left: auto;
          transform: translateY(-50%) translateX(50%) rotate(45deg);
          box-shadow: 3px -3px 5px $tooltip-arrow-shadow-color;
        }
      }

      // Makes tooltip to appear on top
      &--top {
        top: auto;
        bottom: 100%;
        left: 50%;
        margin-bottom: get-spacing(xs);
        margin-left: 0;
        transform: translateY(0%) translateX(-50%);

        &::before {
          top: auto;
          bottom: 0;
          left: 50%;
          margin-bottom: get-spacing(xs);
          margin-left: 0;
          transform: translateY(150%) translateX(-50%) rotate(-45deg);
        }
      }

      // Makes tooltip to appear at the bottom
      &--bottom {
        top: 100%;
        left: 50%;
        margin-top: get-spacing(xs);
        margin-left: 0;
        transform: translateY(0%) translateX(-50%);

        &::before {
          top: 0;
          left: 50%;
          transform: translateY(-50%) translateX(-50%) rotate(45deg);
          box-shadow: -3px -3px 5px $tooltip-arrow-shadow-color;
        }
      }

      &__close {
        cursor: pointer;
      }
    }

    // wrapper that makes is possible to position the tooltip correctly
    // should be added around or to the element that triggers tooltip
    &tooltip-target {
      position: relative;
    }
  }
}
