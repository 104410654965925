// ----------------------------------------------
// Input (text) (.[prefix-]input)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &input {
    @include basic-input;

    &:focus,
    &--active {
      border: 1px solid $input-active-border-color;
      outline: none;
    }

    &[disabled],
    &--disabled {
      opacity: .5;
    }

    &--valid {
      padding-right: get-spacing(sm);
      @include input-icon-valid();
    }

    &--error {
      padding-right: get-spacing(sm);
      @include input-icon-error();
      border: 1px solid $input-error-border-color;
    }

    &--has-icon {
      padding-left: 40px;
    }
  }

  &field {
    position: relative;

    &__icon {
      position: absolute;
      top: 50%;
      left: get-spacing(xs);
      transform: translateY(-50%);
      z-index: z(form-label) + 1;

      &::before {
        margin-left: 0;
      }
    }
  }

}
