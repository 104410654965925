// ------------------------------------
// COLOR VARIABLES
// ------------------------------------

// Brand colors
$uranium-green-color: #73d700;
$honey-orange-color: #ffad00;

// Greens
$matcha-green-color: #a6ec55;
$lemongrass-green-color: #91e82c;
$cucumber-green-color: #5cac00;
$avocado-green-color: #488500;

// Oranges
$carrot-orange-color: #ffcd64;
$pumpkin-orange-color: #ffbf39;
$cinnamon-orange-color: #c68600;
$brick-orange-color: #9b6900;

// Greys
$obsidian-black-color: #444;
$boulder-grey-color: #757575;
$space-grey-color: #8b8b8b;
$silver-grey-color: #c8c8c8;
$mercury-grey-color: #e1e1e1;
$alabaster-grey-color: #f7f7f7;
$plain-white-color: #fff;

// Helper colors
$strawberry-red-color: #ff5700;
$cherry-red-color: #c64300;
$curacao-blue-color: #00b5e2;
