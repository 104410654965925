// ----------------------------------------------
// Popu windows
// ----------------------------------------------
// .[prefix-]popup
// .[prefix-]overlay


@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$popup-width-mobile: 330px;
$popup-width-desktop: 600px;
$popup-bg-color: $primary-bg-color;
$popup-border-radius: $primary-border-radius;
$popup-overlay-box-shadow: 0 0 0 9999px $box-shadow-color;
$popup-change-size-bp: sm;

#{$config-class-prefix} {
  &popup {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z('popup');

    &--active {
      display: block;
    }

    &__body {
      position: fixed;
      top: 50%;
      left: 50%;
      width: $popup-width-mobile;
      max-width: 100%;
      max-height: 100%;
      padding: get-spacing(sm);
      transform: translateY(-50%) translateX(-50%);
      border-radius: $popup-border-radius;
      background: $popup-bg-color;
      @include font-primary-normal;
      line-height: $line-height-primary;
      text-align: center;
      z-index: z('popup') + 1;
      // makes popup scrollable if it's too big
      overflow-y: auto;

      @include on-bp($popup-change-size-bp) {
        width: $popup-width-desktop;
        padding: get-spacing(md);
      }
    }

    &__icon {
      padding-bottom: get-spacing(xs);
    }

    &__title {
      margin: 0;
      padding-bottom: get-spacing(xs);
      color: $header-font-color;
      font-size: 28px;
      font-weight: $font-weight-bold;
      line-height: 34px;
    }

    &__content {
      padding-bottom: get-spacing(xs);
    }

    &__actions {
      display: flex;
      // moves first (main) CTA to the right when displaying side by side
      flex-direction: row-reverse;
      flex-wrap: wrap;
      justify-content: center;

      // @todo try to get rid of the nesting and overriding default button behaviour here
      #{$config-class-prefix}btn + #{$config-class-prefix}btn {
        @include on-bp($popup-change-size-bp) {
          margin-right: get-spacing(xs);
          margin-left: 0;
        }
      }
    }
  }

  // General overlay element, can be used on it's own for sliding bars or whatever

  &overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay-color;
    // overlay z-index is just bellow the popup
    z-index: z('popup');
  }
}
