
// ----------------------------------------------
// blockquote (.[prefix-]blockquote)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

@include exports('blockquote') {
  #{$config-class-prefix} {
    &blockquote {
      position: relative;
      margin: 0 0 get-spacing(xs);
      padding: get-spacing(sm) 0 0 80px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 64px;
        height: 40px;
        background: svg-url-encode('<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.8 100"><defs><style>.cls-1{fill:#8c8c8c;}</style></defs><title>quote1</title><path class="cls-1" d="M159.4,62.9A32.2,32.2,0,0,0,129,35.7c3.7-13.8,12.8-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.8-11s-0.2-.9-1.6-0.7c-38,4.3-63.7,33.2-58.9,68.3,4.4,24.3,23.4,33.7,41,31.2a32.4,32.4,0,0,0,27-36.7h0ZM38.1,35.7c3.6-13.8,13-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.6-11S61-.2,59.6,0C21.6,4.1-3.9,33,.5,68.2c4.6,24.5,23.8,33.9,41.2,31.4A32.1,32.1,0,0,0,68.5,62.9,31.7,31.7,0,0,0,38.1,35.7Z"/></svg>');
        content: '';
      }
    }
  }
}
