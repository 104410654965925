// ------------------------------------
// Grid utilities
// ------------------------------------
/// @access public
// ------------------------------------

@import '../config/all';

// ------------------------------------
// Grid mixins
// ------------------------------------

// ------------------------------------
// grid-row mixin
// ------------------------------------
// Creates grid row container (based on flexbox)
// ------------------------------------
@mixin grid-row() {
  display: flex;
  flex-wrap: wrap;
}


// ------------------------------------
// grid-col mixin
// ------------------------------------
// Creates grid column
//
/// @param {number} $size - column size in columns from 1 to 12
/// @param {boolean} $is-last - indicates last column in a row, drops side spacing
// ------------------------------------

@mixin grid-col($size, $is-last: false) {
  // overcoming ie11 not supporting calc for flex shorthand declaration
  flex-basis: grid-span($size);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: grid-span($size);
  @if ($is-last) or ($size == 12) {
    margin-right: 0;
  } @else {
    margin-right: grid-gutter();
  }
}

@mixin grid-push($size) {
  margin-left: grid-push($size);
}

// ------------------------------------
// Grid helpers
// ------------------------------------

// ------------------------------------
// grid-gutter function
// ------------------------------------
// Grid gutter getter
// ------------------------------------

@function grid-gutter() {
  @return map-get($grid, 'gutter');
}


// ------------------------------------
// grid-columns function
// ------------------------------------
// Grid columns getter
// ------------------------------------

@function grid-columns() {
  @return map-get($grid, 'columns');
}

// ------------------------------------
// grid-span function
// ------------------------------------
// Grid calc helper, calculates column span width
//
/// @param $size {Integer} - number of columns (from 1 to 12)
/// @return {String} - calc() providing the width of the column
// ------------------------------------

@function grid-span($size) {
  $grid-gutter: grid-gutter();
  $grid-columns: grid-columns();
  // used to overcome weird IE calc specific rounding issues by providing 0.0833 instead of 1/12
  $column-divider: (1/$grid-columns);

  @return calc(#{($size - 1) * $grid-gutter} + (100% - #{$grid-columns * $grid-gutter - $grid-gutter}) * #{$column-divider} * #{$size});
}


// ------------------------------------
// grid-push function
// ------------------------------------
// Grid calc helper, calculates column push width
//
/// @param $size {Integer} - number of columns (from 1 to 12)
/// @return {String} - calc() providing the width of the push gap
// ------------------------------------

@function grid-push($size) {
  $grid-gutter: grid-gutter();
  $grid-columns: grid-columns();
  // used to overcome weird IE calc specific rounding issues by providing 0.0833 instead of 1/12
  $column-divider: (1/$grid-columns);

  @return calc(#{$size * $grid-gutter} + (100% - #{$grid-columns * $grid-gutter - $grid-gutter}) * #{$column-divider} * #{$size});
}
