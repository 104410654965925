// ----------------------------------------------
// Switch (checkbox) (.[prefix-]switch)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$input-switch-thumb-radius: 11px !default;
$input-switch-thumb-height: 22px !default;
$input-switch-thumb-width: 22px !default;
$input-switch-track-width: 40px !default;
$input-switch-track-height: 5px !default;
$input-switch-bg-color: $disabled-color;
$input-switch-control-bg-color: $primary-bg-color;
$input-switch-color: $strong-highlight-color !default;
$input-range-track-radius: $input-switch-track-height/2 !default;


#{$config-class-prefix} {

  &switch {
    display: inline-block;
    position: relative;
    width: $input-switch-track-width;
    height: $input-switch-track-height;
    // aligns control with input elements vertically making it occupying the same space
    margin: 0 0 7px;

    input {
      display: none;

      &:checked + .flix-switch__slider {
        background-color: $input-switch-color;
      }

      &:focus + .flix-switch__slider {
        box-shadow: 0 0 1px $input-switch-color;
      }

      &:checked + .flix-switch__slider:before {
        transform: translateX(18px);
      }
    }
  }

  &switch__slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .4s;
    border-radius: $input-range-track-radius;
    background-color: $input-switch-bg-color;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: $input-switch-thumb-width;
      height: $input-switch-thumb-height;
      margin-top: -$input-switch-thumb-height/2;
      transition: .4s;
      border: 1px solid $primary-line-color;
      border-radius: 50%;
      background-color: $input-switch-control-bg-color;
      content: '';
    }
  }
}
