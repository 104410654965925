// ----------------------------------------------
// Notice box (.[prefix-]notification)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';
// base icon mixins to get arrow icons
@import '../icons/base-mixins';
@import '../icons/icon-mixins';

// ------------------------------------
// style variables
// ------------------------------------
$notification-bg-color: $primary-bg-color;

#{$config-class-prefix} {
  &notification {
    position: relative;
    width: 100%;
    margin: get-spacing(xs) 0;
    padding: get-spacing(xs) get-spacing(md);
    border-radius: $primary-border-radius;
    background: $notification-bg-color;
    font-size: $font-size-primary;
    font-weight: $font-weight-bold;
    line-height: 22px;
    text-align: center;
    box-shadow: $primary-box-shadow;

    &::before {
      // @todo find a better way to put icons in here
      @include flix-icon-abstract; // solves icon rendering issues in some browsers and OS'
      @include flix-icon;
      @include flix-icon-info;
      position: absolute;
      top: 50%;
      left: 15px;
      margin-top: -0.5em;
      color: $neutral-color;
    }

    &--error {
      &::before {
        @include flix-icon-attention;
        color: $fail-color;
      }
    }

    &--success {
      &::before {
        @include flix-icon-checkmark-strong;
        color: $primary-brand-color;
      }
    }


    &__close {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -0.5em;
      cursor: pointer;
    }
  }
}
