// ----------------------------------------------
// Typography elements
// ----------------------------------------------
// .[prefix-]text
// .[prefix-]link
// .[prefix-]blockquote
// .[prefix-]h1
// .[prefix-]h2
// .[prefix-]h3
// .[prefix-]list
// .[prefix-]list-general
// ------------------------------------
// @TODO: this stylesheet is more a set of scope styles under the .[prefix-]text class
// we need to find a way to split this into proper indepentend components

@import '../config/all';
@import '../utils/all';
// importing typography related components
@import 'blockquote';
@import 'link';
@import 'heading';

#{$config-class-prefix} {

  // Text
  // ----------------------------------------------

  &text,
  &text p {
    margin: 0;
    margin-bottom: get-spacing(xs);
    color: $primary-content-color;
    font-size: $font-size-primary;
    font-weight: $font-weight-normal;
    line-height: $line-height-primary;
  }

  &fineprint {
    margin: 0;
    margin-bottom: get-spacing(xs);
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: 17px;
  }

  // Links
  // ----------------------------------------------

  &text a {
    @extend #{$config-class-prefix}link;
  }

  // Headers
  // ----------------------------------------------

  &text h1 {
    @extend #{$config-class-prefix}h1;
  }

  &text h2 {
    @extend #{$config-class-prefix}h2;
  }

  &text h3 {
    @extend #{$config-class-prefix}h3;
  }

  &text h4 {
    @extend #{$config-class-prefix}h4;
  }

  // Blockquote
  // ----------------------------------------------

  &text blockquote {
    @extend #{$config-class-prefix}blockquote;
  }

}
