// ------------------------------------
// positioning utilities 
// ------------------------------------
/// @access private
// ------------------------------------

@import '../config/all';

// ------------------------------------
// get-spacing function 
// ------------------------------------
// proxy for spacing variables
//
/// @param {String} $spacing - spacing shorthand, on of xs, sm, md, lg
/// @return {String} - spacing value in pixels
// ------------------------------------

@function get-spacing($spacing) {
  @return map-get($s-map, $spacing);
}


// ------------------------------------
// z-index function 
// ------------------------------------
// store and retrieves z index integers for consistency
//
/// @param {string} $layer - defines z-index layer

@function z($layer) {
  $z-layers: (
    'base': 1,
    'form-label': 1,
    'dropdown': 10,
    'header': 1000,
    'popup': 1100,
    'side-menu': 1209
  );
  @if not map-has-key($z-layers, $layer) {
    @warn 'No layer found for `#{$layer}` in $z-layers map. Property omitted.';
  }

  @return map-get($z-layers, $layer);
}
