// ----------------------------------------------
// Numbered list element
// ----------------------------------------------
// .[prefix-]list-numbered
// ------------------------------------

@import '../config/all';
@import '../utils/all';


#{$config-class-prefix} {

  // Numbered list
  // ----------------------------------------------

  // @todo here and everywhere where `.flix-text` typography styles are present -> make this more elegant and move them to a separate place
  &list-numbered,
  &text ol {
    margin: 0 0 get-spacing(xs);
    padding-left: get-spacing(xs);
    list-style-type: none;
    counter-reset: numbered-list-counter;
  }

  &list-numbered__item,
  &text ol li {
    display: table; // achieving a correct behaviour for multi-line list items, they need to be aligned in one line
    position: relative;

    &::before {
      display: table-cell;
      padding-right: 6px; // to achieve the same horizontal spacing as with bullet lists, so they look consistent
      font-weight: $font-weight-bold;
      // number should be followed with a dot "."
      content: counters(numbered-list-counter, '.') '.';
      counter-increment: numbered-list-counter;
    }
  }

  // nested lists
  &list-numbered &list-numbered,
  &text ol ol {
    width: 100%; // make sure nested list gets a new line
    margin: 0; // removes bottom margins for nested lists
    padding-left: 0;
  }

  // nested list items
  &list-numbered &list-numbered &list-numbered__item,
  &text ol ol li {
    &::before {
      font-weight: $font-weight-normal;
      content: counters(numbered-list-counter, '.'); // no need for an ending dot for sub items
    }
  }
}
