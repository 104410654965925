// ----------------------------------------------
// Tag (.[prefix-]tag)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$tag-height: 30px;
$tag-radius: $tag-height/2; // we want them to be round
$tag-bg-color: $primary-bg-color;
$tag-color: $success-color;
$tag-font-weight: $font-weight-semibold;
$tag-font-size: $font-size-primary;


#{$config-class-prefix} {
  &tag {
    display: inline-block;
    max-width: 100%;
    height: $tag-height;
    padding: 0 get-spacing(xs);
    border: 1px solid $tag-color;
    border-radius: $tag-radius;
    background: $tag-bg-color;
    color: $tag-color;
    font-size: $tag-font-size;
    font-weight: $tag-font-weight;
    // compensating the 1px borders on the sides
    line-height: $tag-height - 2;
    text-decoration: none;
    text-overflow: ellipsis;
    // no multiline text is allowed within the tag element
    white-space: nowrap;
    overflow: hidden;
    // makes muti-line layout the same in different browsers, aligns tags with text in the same container
    vertical-align: middle;

    // adding borders of the same background color to different types to achieve a consistent height without playing with paddings
    &--success {
      border: 0;
      background: $success-color;
      color: $primary-bg-color;
      line-height: $tag-height;
    }

    &--warning {
      border: 0;
      background: $warning-color;
      color: $primary-bg-color;
      line-height: $tag-height;
    }

    &--danger {
      border: 0;
      background: $fail-color;
      color: $primary-bg-color;
      line-height: $tag-height;
    }

    &--disabled {
      border: 0;
      background: $disabled-color;
      color: $primary-bg-color;
      line-height: $tag-height;
    }
  }

  &tag__icon {
    // strip left margins to align the icons perfectly with the text
    &:first-child::before {
      margin-left: 0;
    }
  }
}
