// ------------------------------------
// Form elements
// ------------------------------------
// .[prefix-]control
// .[prefix-]label
// ------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$label-bg-color: transparent;
$label-bg-color-mobile: $primary-bg-color;
$label-font-size-mobile: $font-size-small;
$label-font-size: $font-size-primary;

#{$config-class-prefix} {

  // Form control row
  // ----------------------------------------------

  &control {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: get-spacing(sm);

    @include on-bp(xl) {
      margin-bottom: get-spacing(xs);
    }

    &--is-last {
      margin-bottom: 0;
    }

    &--small {
      width: 50%;
    }
  }

  &label {
    display: block;
    position: absolute;
    top: -6px;
    left: 10px;
    padding: 0 5px;
    background: $label-bg-color-mobile;
    color: $primary-content-color;
    font-family: $font-family-primary;
    font-size: $label-font-size-mobile;
    font-weight: $font-weight-normal;
    line-height: 1em;
    z-index: z('form-label');

    @include on-bp(xl) {
      position: static;
      top: auto;
      left: auto;
      padding: 0 0 2px;
      background: $label-bg-color;
      color: $secondary-content-color;
      font-size: $label-font-size;
      line-height: $line-height-primary;
    }

    &--required:after {
      display: inline;
      content: '*';
    }
  }
}
