// ------------------------------------
// Header elements
// ------------------------------------
// .[prefix-]header-nav-wrapper
// .[prefix-]header-navbar
// .[prefix-]header-subnav
// .[prefix-]header-nav-toggle
// .[prefix-]header-widgets
// .[prefix-]header-brand
// .[prefix-]header
// ------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$header-bg-color: $primary-brand-color;
$header-default-expand-bp: sm;
$header-nav-height-tablet: 40px;
$header-nav-height: 60px;
$header-nav-bg-color-tablet: $cucumber-green-color;
$header-nav-bg-color: transparent;
$header-nav-link-color: $primary-bg-color;
$header-sub-nav-border-before-color: $primary-bg-color;
$header-sub-nav-bg-color: $primary-bg-color;
$header-menu-toggle-color:  $primary-bg-color;
$header-subnav-width: 250px;
$header-subnav-box-shadow: $primary-box-shadow;
$header-logo-height: 22px;


// ------------------------------------
// HEADER private mixins
// ------------------------------------

// header navigation mixin, used to generate styles dynamically together with "--expand"  modifier classes
// @param {string} $expand-bp - one of the map keys in breakpoints list
@mixin header-nav($expand-bp: $header-default-expand-bp) {
  // menu sizes: 250px mobile, 12 columns - tablet, 8  grid columns - desktop
  #{$config-class-prefix}header-nav-wrapper {
    // sass-lint:disable no-vendor-prefixes
    position: fixed;
    top: $header-nav-height;
    bottom: 0;
    left: -250px;
    width: 250px;
    padding: 0 get-spacing(xs);
    transition: left 250ms ease, box-shadow 250ms ease;
    background: $primary-bg-color;
    box-shadow: none;
    z-index: z('dropdown');
    overflow-y: auto;
    // improves scrolling on touch devices
    -webkit-overflow-scrolling: touch;


    @include on-bp($expand-bp) {
      // dropping mobile styles
      position: static;
      top: auto;
      bottom: auto;
      left: auto;
      width: auto;
      padding: 0;
      // applying tablet styles
      background: $header-nav-bg-color-tablet;
      box-shadow: none;
      z-index: z('base');
      overflow-y: visible;
    }

    @include on-bp(xl) {
      background: $header-nav-bg-color;
    }

    &--visible {
      // animating menu slide, adding an overlay
      left: 0;
      box-shadow: 0 9999px 0 9999px $box-shadow-color;

      @include on-bp($expand-bp) {
        box-shadow: none;
      }
    }
  }

  #{$config-class-prefix}header-navbar {
    @include grid-col(12);
    // dropping mobile styles
    position: static;
    top: auto;
    // applying tablet styles
    order: 3;
    padding: 0;
    background: $header-nav-bg-color-tablet;
    box-shadow: none;
    z-index: z('base');
    overflow-y: visible;

    @include on-bp(xl) {
      @include grid-col(8);
      order: 2;
      background: $header-nav-bg-color;
    }
  }

  #{$config-class-prefix}header-nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    // tablet layout starts for the nav container
    @include on-bp($expand-bp) {
      align-items: center;
      justify-content: center;
    }

    @include on-bp(xl) {
      justify-content: flex-start;
    }

    &__item {
      display: flex;
      position: relative;
      flex-basis: 100%;
      flex-wrap: wrap;
      align-items: center;
      min-height: $header-nav-height;

      // tablet layout starts for the nav item
      @include on-bp($expand-bp) {
        flex-basis: auto;
        flex-wrap: nowrap;
        min-height: 0;
        padding-right: get-spacing(xs);
      }

      &:last-child {
        @include on-bp($expand-bp) {
          padding-right: 0;
        }
      }
    }

    // draws dividing line in between the items
    // manually includes prefix to avoid weird nesting behaviour
    &__item + #{$config-class-prefix}header-nav__item {
      border-top: 1px solid $primary-line-color;

      @include on-bp($expand-bp) {
        padding-left: get-spacing(xs);
        border-top: 0;
      }

      &::after {
        @include on-bp($expand-bp) {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          height: 16px;
          margin-top: -8px;
          border-left: 1px solid $header-nav-link-color;
          content: '';
        }
      }
    }

    &__link {
      display: block;
      position: relative;
      flex-basis: 100%;
      height: $header-nav-height;
      color: $primary-content-color;
      font-size: $font-size-primary;
      font-weight: $font-weight-semibold;
      line-height: $header-nav-height;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      // tablet layout starts for the nav link
      @include on-bp($expand-bp) {
        flex-basis: auto;
        height: $header-nav-height-tablet;
        color: $header-nav-link-color;
        line-height: $header-nav-height-tablet;
      }

      @include on-bp(xl) {
        height: $header-nav-height;
        line-height: $header-nav-height;
      }
    }

    // side line for mobile version
    &__link:hover::before,
    &__link--active::before {
      position: absolute;
      left: -#{get-spacing(xs)};
      height: 100%;
      border-left: 4px solid $strong-highlight-color;
      content: '';

      // bottom line for tablet/desktop
      @include on-bp($expand-bp) {
        left: auto;
        width: 100%;
        border-bottom: 4px solid $header-sub-nav-border-before-color;
        border-left: 0;
      }
    }

    &__item--has-subnav {
      #{$config-class-prefix}header-nav__link {
        &::after {
          @include flix-icon-abstract;
          @include flix-icon;
          @include flix-icon-arrow-down;
          position: absolute;
          right: 0;

          @include on-bp($expand-bp) {
            position: static;
            right: auto;
          }
        }
      }
    }
  }

  // Subnavigation
  // ----------------------------------------------

  #{$config-class-prefix}header-subnav {
    display: none;
    flex-basis: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    // tablet layout starts for the subnavigation
    @include on-bp($expand-bp) {
      position: absolute;
      top: 100%;
      left: 50%;
      width: $header-subnav-width;
      margin-top: -4px;
      // compensating the sabnav indication arrow and shifting content in the middle of the wording itself
      margin-left: (- $header-subnav-width / 2) - 10px;
      padding: get-spacing(xs) 0;
      border-radius: $primary-border-radius;
      background: $header-sub-nav-bg-color;
      box-shadow: $header-subnav-box-shadow;
      z-index: z('dropdown');
    }

    @include on-bp(xl) {
      // different spacing since desktop and tablet navs have different heights
      margin-top: -10px;
    }

    // top arrow for the subnav, needed for tablet/desktop layout only
    &::before {
      @include on-bp($expand-bp) {
        position: absolute;
        top: -5px;
        left: 50%;
        width: 0;
        height: 0;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $header-sub-nav-border-before-color;
        border-left: 6px solid transparent;
        content: '';
      }
    }

    // triggers subnavigation visibility when main nav item is hovered
    #{$config-class-prefix}header-nav__item:hover & {
      display: block;
    }

    &__item {
      margin-bottom: get-spacing(xs);

      @include on-bp(sm) {
        margin-bottom: 0;
      }
    }

    &__link {
      display: block;
      position: relative;
      width: 100%;
      padding: 5px get-spacing(xs);
      color: $primary-content-color;
      font-size: $font-size-primary;
      font-weight: $font-weight-semibold;
      text-decoration: none;
      cursor: pointer;

      @include on-bp($expand-bp) {
        padding: 5px get-spacing(sm);
      }
    }

    &__link:hover {
      padding-left: 11px;
      border-left: 4px solid $strong-highlight-color;

      @include on-bp($expand-bp) {
        padding-left: 26px;
      }
    }
  }
}


// ------------------------------------
// HEADER styles
// ------------------------------------

#{$config-class-prefix} {
  &header {
    // sass-lint:disable no-vendor-prefixes
    position: fixed;
    top: 0;
    width: 100%;
    background: $header-bg-color;
    // fixes safari font antialiasing issues
    z-index: z('header');
    -webkit-font-smoothing: subpixel-antialiased;

    // makes header to scroll together with the page
    &--unfixed {
      position: static;
    }

    &__inner {
      position: relative;
      @include grid-row();
      align-items: center;
      //justify-content: center;
      min-width: 320px;
      max-width: 1200px;
      margin: 0 auto;

      @include on-bp(xl) {
        padding: 0 get-spacing(xs);
      }
    }
  }

  // mobile navigation toggler, disabled by default
  // "--expand-**" modifier should be used in order for it to show when the main nav is collapsed
  &header-nav-toggle {
    display: none;
    position: absolute;
    top: 0;
    width: 60px;
    height: $header-nav-height;
    padding: 0 get-spacing(xs);
    border: 0;
    background: none;
    color: $header-nav-link-color;
    font-size: $font-size-primary;
    line-height: $header-nav-height;
  }

  // styleguide recommendation is to have 2 standard grid column for the logo container
  &header-brand {
    @include grid-col(12);
    display: flex;
    align-items: stretch;
    justify-content: center;
    order: 1;
    height: $header-nav-height;

    @include on-bp(sm) {
      @include grid-col(6);
      @include grid-push(3);
    }

    @include on-bp(xl) {
      @include grid-col(2);
      justify-content: flex-start;
      margin-left: 0;
    }

    &__link {
      display: flex;
      align-items: center;
    }

    &__img {
      width: auto;
      height: $header-logo-height;
      border: 0; // fixes weird IE10 image link border
    }
  }

  // 2 columns for additional widget contend in the header
  // hidden on mobile (bellow sm breakpoint)
  &header-widgets {
    display: none;

    @include on-bp(sm) {
      display: flex;
      @include grid-col(3, $is-last: true);
      justify-content: flex-end;
      order: 2;
      padding-right: get-spacing(xs);
    }

    @include on-bp(xl) {
      @include grid-col(2, $is-last: true);
      order: 3;
      // header inner has side padding already so we can zero this one
      padding-right: 0;
    }
  }
}

@include header-nav($expand-bp: sm);

// generating flix-header--expand-sm etc. classes to control the tablet menu version appearance
@if $header-expand-feature {
  @each $size, $value in $breakpoints {
    #{$config-class-prefix}header--expand-#{$size} {
      // show menu toggle control when main navigation is hidden
      #{$config-class-prefix}header-nav-toggle {
        display: block;
        @include on-bp($size) {
          display: none;
        }
      }

      @include header-nav($expand-bp: $size);
    }
  }
} @else {
  // show menu toggle control when main navigation is hidden
  #{$config-class-prefix}header-nav-toggle {
    display: block;
    @include on-bp(sm) {
      display: none;
    }
  }
}
