// ------------------------------------
// NOTE: this is an autogenerated file
// Refer to fontello:build script in './scripts/fontello'
// ------------------------------------

// ------------------------------------
// flixicons font-face
// ------------------------------------

// sass-lint:disable no-vendor-prefixes no-duplicate-properties class-name-format

@font-face {
  font-family: 'flixicons';
  font-style: normal;
  font-weight: normal;
  src: url('../font/flix.woff2?1536136503516') format('woff2'),
  url('../font/flix.woff?1536136503516') format('woff'),
  url('../font/flix.ttf?1536136503516') format('truetype');
  // hides the icons while font is loading
  font-display: block;
}

// ------------------------------------
// flixicons classes
// ------------------------------------

.flix-icon-account-attention-solid:before {
  @include flix-icon-account-attention-solid;
}

.flix-icon-account-attention:before {
  @include flix-icon-account-attention;
}

.flix-icon-account-settings-solid:before {
  @include flix-icon-account-settings-solid;
}

.flix-icon-account-settings:before {
  @include flix-icon-account-settings;
}

.flix-icon-account-solid:before {
  @include flix-icon-account-solid;
}

.flix-icon-account:before {
  @include flix-icon-account;
}

.flix-icon-airport-solid:before {
  @include flix-icon-airport-solid;
}

.flix-icon-airport:before {
  @include flix-icon-airport;
}

.flix-icon-arrow-down:before {
  @include flix-icon-arrow-down;
}

.flix-icon-arrow-left:before {
  @include flix-icon-arrow-left;
}

.flix-icon-arrow-right:before {
  @include flix-icon-arrow-right;
}

.flix-icon-arrow-up:before {
  @include flix-icon-arrow-up;
}

.flix-icon-attention-solid:before {
  @include flix-icon-attention-solid;
}

.flix-icon-attention:before {
  @include flix-icon-attention;
}

.flix-icon-audio-solid:before {
  @include flix-icon-audio-solid;
}

.flix-icon-audio:before {
  @include flix-icon-audio;
}

.flix-icon-beach-solid:before {
  @include flix-icon-beach-solid;
}

.flix-icon-beach:before {
  @include flix-icon-beach;
}

.flix-icon-beer:before {
  @include flix-icon-beer;
}

.flix-icon-bike-solid:before {
  @include flix-icon-bike-solid;
}

.flix-icon-bike:before {
  @include flix-icon-bike;
}

.flix-icon-birthday-solid:before {
  @include flix-icon-birthday-solid;
}

.flix-icon-birthday:before {
  @include flix-icon-birthday;
}

.flix-icon-book-solid:before {
  @include flix-icon-book-solid;
}

.flix-icon-book:before {
  @include flix-icon-book;
}

.flix-icon-booster-solid:before {
  @include flix-icon-booster-solid;
}

.flix-icon-booster:before {
  @include flix-icon-booster;
}

.flix-icon-burger:before {
  @include flix-icon-burger;
}

.flix-icon-bus-night-solid:before {
  @include flix-icon-bus-night-solid;
}

.flix-icon-bus-night:before {
  @include flix-icon-bus-night;
}

.flix-icon-bus-service-solid:before {
  @include flix-icon-bus-service-solid;
}

.flix-icon-bus-service:before {
  @include flix-icon-bus-service;
}

.flix-icon-bus-solid:before {
  @include flix-icon-bus-solid;
}

.flix-icon-bus-stop-solid:before {
  @include flix-icon-bus-stop-solid;
}

.flix-icon-bus-stop:before {
  @include flix-icon-bus-stop;
}

.flix-icon-bus-time-solid:before {
  @include flix-icon-bus-time-solid;
}

.flix-icon-bus-time:before {
  @include flix-icon-bus-time;
}

.flix-icon-bus:before {
  @include flix-icon-bus;
}

.flix-icon-buses:before {
  @include flix-icon-buses;
}

.flix-icon-calendar-solid:before {
  @include flix-icon-calendar-solid;
}

.flix-icon-calendar:before {
  @include flix-icon-calendar;
}

.flix-icon-cart-solid:before {
  @include flix-icon-cart-solid;
}

.flix-icon-cart:before {
  @include flix-icon-cart;
}

.flix-icon-chat-solid:before {
  @include flix-icon-chat-solid;
}

.flix-icon-chat:before {
  @include flix-icon-chat;
}

.flix-icon-check-solid:before {
  @include flix-icon-check-solid;
}

.flix-icon-check:before {
  @include flix-icon-check;
}

.flix-icon-checkmark-strong-solid:before {
  @include flix-icon-checkmark-strong-solid;
}

.flix-icon-checkmark-strong:before {
  @include flix-icon-checkmark-strong;
}

.flix-icon-checkmark:before {
  @include flix-icon-checkmark;
}

.flix-icon-city-solid:before {
  @include flix-icon-city-solid;
}

.flix-icon-city:before {
  @include flix-icon-city;
}

.flix-icon-close:before {
  @include flix-icon-close;
}

.flix-icon-collapse:before {
  @include flix-icon-collapse;
}

.flix-icon-connection-transfer:before {
  @include flix-icon-connection-transfer;
}

.flix-icon-connection:before {
  @include flix-icon-connection;
}

.flix-icon-credit-card-solid:before {
  @include flix-icon-credit-card-solid;
}

.flix-icon-credit-card:before {
  @include flix-icon-credit-card;
}

.flix-icon-customer-solid:before {
  @include flix-icon-customer-solid;
}

.flix-icon-customer:before {
  @include flix-icon-customer;
}

.flix-icon-delete-solid:before {
  @include flix-icon-delete-solid;
}

.flix-icon-delete:before {
  @include flix-icon-delete;
}

.flix-icon-directions-solid:before {
  @include flix-icon-directions-solid;
}

.flix-icon-directions:before {
  @include flix-icon-directions;
}

.flix-icon-discount-solid:before {
  @include flix-icon-discount-solid;
}

.flix-icon-discount:before {
  @include flix-icon-discount;
}

.flix-icon-drinks-solid:before {
  @include flix-icon-drinks-solid;
}

.flix-icon-drinks:before {
  @include flix-icon-drinks;
}

.flix-icon-driver-solid:before {
  @include flix-icon-driver-solid;
}

.flix-icon-driver:before {
  @include flix-icon-driver;
}

.flix-icon-ebus-solid:before {
  @include flix-icon-ebus-solid;
}

.flix-icon-ebus:before {
  @include flix-icon-ebus;
}

.flix-icon-edit-solid:before {
  @include flix-icon-edit-solid;
}

.flix-icon-edit:before {
  @include flix-icon-edit;
}

.flix-icon-entertainment-solid:before {
  @include flix-icon-entertainment-solid;
}

.flix-icon-entertainment:before {
  @include flix-icon-entertainment;
}

.flix-icon-expand:before {
  @include flix-icon-expand;
}

.flix-icon-filter:before {
  @include flix-icon-filter;
}

.flix-icon-full-screen:before {
  @include flix-icon-full-screen;
}

.flix-icon-games-solid:before {
  @include flix-icon-games-solid;
}

.flix-icon-games:before {
  @include flix-icon-games;
}

.flix-icon-group-solid:before {
  @include flix-icon-group-solid;
}

.flix-icon-group:before {
  @include flix-icon-group;
}

.flix-icon-headphones-solid:before {
  @include flix-icon-headphones-solid;
}

.flix-icon-headphones:before {
  @include flix-icon-headphones;
}

.flix-icon-home-solid:before {
  @include flix-icon-home-solid;
}

.flix-icon-home:before {
  @include flix-icon-home;
}

.flix-icon-info-solid:before {
  @include flix-icon-info-solid;
}

.flix-icon-info:before {
  @include flix-icon-info;
}

.flix-icon-invoice:before {
  @include flix-icon-invoice;
}

.flix-icon-label-attention-solid:before {
  @include flix-icon-label-attention-solid;
}

.flix-icon-label-attention:before {
  @include flix-icon-label-attention;
}

.flix-icon-label-solid:before {
  @include flix-icon-label-solid;
}

.flix-icon-label:before {
  @include flix-icon-label;
}

.flix-icon-leaf-solid:before {
  @include flix-icon-leaf-solid;
}

.flix-icon-leaf:before {
  @include flix-icon-leaf;
}

.flix-icon-link-solid:before {
  @include flix-icon-link-solid;
}

.flix-icon-link:before {
  @include flix-icon-link;
}

.flix-icon-location-solid:before {
  @include flix-icon-location-solid;
}

.flix-icon-location:before {
  @include flix-icon-location;
}

.flix-icon-lost-solid:before {
  @include flix-icon-lost-solid;
}

.flix-icon-lost:before {
  @include flix-icon-lost;
}

.flix-icon-luggage-additional-solid:before {
  @include flix-icon-luggage-additional-solid;
}

.flix-icon-luggage-additional:before {
  @include flix-icon-luggage-additional;
}

.flix-icon-luggage-bulky-solid:before {
  @include flix-icon-luggage-bulky-solid;
}

.flix-icon-luggage-bulky:before {
  @include flix-icon-luggage-bulky;
}

.flix-icon-luggage-hand-solid:before {
  @include flix-icon-luggage-hand-solid;
}

.flix-icon-luggage-hand:before {
  @include flix-icon-luggage-hand;
}

.flix-icon-luggage-solid:before {
  @include flix-icon-luggage-solid;
}

.flix-icon-luggage:before {
  @include flix-icon-luggage;
}

.flix-icon-magnifier-solid:before {
  @include flix-icon-magnifier-solid;
}

.flix-icon-magnifier:before {
  @include flix-icon-magnifier;
}

.flix-icon-map-solid:before {
  @include flix-icon-map-solid;
}

.flix-icon-map:before {
  @include flix-icon-map;
}

.flix-icon-men-women-solid:before {
  @include flix-icon-men-women-solid;
}

.flix-icon-men-women:before {
  @include flix-icon-men-women;
}

.flix-icon-minus:before {
  @include flix-icon-minus;
}

.flix-icon-mountains-solid:before {
  @include flix-icon-mountains-solid;
}

.flix-icon-mountains:before {
  @include flix-icon-mountains;
}

.flix-icon-newsletter-solid:before {
  @include flix-icon-newsletter-solid;
}

.flix-icon-newsletter:before {
  @include flix-icon-newsletter;
}

.flix-icon-night-solid:before {
  @include flix-icon-night-solid;
}

.flix-icon-night:before {
  @include flix-icon-night;
}

.flix-icon-passport-solid:before {
  @include flix-icon-passport-solid;
}

.flix-icon-passport:before {
  @include flix-icon-passport;
}

.flix-icon-person-solid:before {
  @include flix-icon-person-solid;
}

.flix-icon-person:before {
  @include flix-icon-person;
}

.flix-icon-phone-solid:before {
  @include flix-icon-phone-solid;
}

.flix-icon-phone-tablet-solid:before {
  @include flix-icon-phone-tablet-solid;
}

.flix-icon-phone-tablet:before {
  @include flix-icon-phone-tablet;
}

.flix-icon-phone:before {
  @include flix-icon-phone;
}

.flix-icon-pin-solid:before {
  @include flix-icon-pin-solid;
}

.flix-icon-pin:before {
  @include flix-icon-pin;
}

.flix-icon-plane-solid:before {
  @include flix-icon-plane-solid;
}

.flix-icon-plane:before {
  @include flix-icon-plane;
}

.flix-icon-plus:before {
  @include flix-icon-plus;
}

.flix-icon-q-and-a-solid:before {
  @include flix-icon-q-and-a-solid;
}

.flix-icon-q-and-a:before {
  @include flix-icon-q-and-a;
}

.flix-icon-question-solid:before {
  @include flix-icon-question-solid;
}

.flix-icon-question:before {
  @include flix-icon-question;
}

.flix-icon-real-time-solid:before {
  @include flix-icon-real-time-solid;
}

.flix-icon-real-time:before {
  @include flix-icon-real-time;
}

.flix-icon-rebook-solid:before {
  @include flix-icon-rebook-solid;
}

.flix-icon-rebook:before {
  @include flix-icon-rebook;
}

.flix-icon-refresh:before {
  @include flix-icon-refresh;
}

.flix-icon-seat-belt-solid:before {
  @include flix-icon-seat-belt-solid;
}

.flix-icon-seat-belt:before {
  @include flix-icon-seat-belt;
}

.flix-icon-seat-solid:before {
  @include flix-icon-seat-solid;
}

.flix-icon-seat:before {
  @include flix-icon-seat;
}

.flix-icon-secure-payment-solid:before {
  @include flix-icon-secure-payment-solid;
}

.flix-icon-secure-payment:before {
  @include flix-icon-secure-payment;
}

.flix-icon-security-solid:before {
  @include flix-icon-security-solid;
}

.flix-icon-security:before {
  @include flix-icon-security;
}

.flix-icon-settings-solid:before {
  @include flix-icon-settings-solid;
}

.flix-icon-settings:before {
  @include flix-icon-settings;
}

.flix-icon-sit-solid:before {
  @include flix-icon-sit-solid;
}

.flix-icon-sit:before {
  @include flix-icon-sit;
}

.flix-icon-snacks-solid:before {
  @include flix-icon-snacks-solid;
}

.flix-icon-snacks:before {
  @include flix-icon-snacks;
}

.flix-icon-socket-solid:before {
  @include flix-icon-socket-solid;
}

.flix-icon-socket:before {
  @include flix-icon-socket;
}

.flix-icon-stopwatch-solid:before {
  @include flix-icon-stopwatch-solid;
}

.flix-icon-stopwatch:before {
  @include flix-icon-stopwatch;
}

.flix-icon-stroller-solid:before {
  @include flix-icon-stroller-solid;
}

.flix-icon-stroller:before {
  @include flix-icon-stroller;
}

.flix-icon-suitcase-solid:before {
  @include flix-icon-suitcase-solid;
}

.flix-icon-suitcase:before {
  @include flix-icon-suitcase;
}

.flix-icon-switch-vertical:before {
  @include flix-icon-switch-vertical;
}

.flix-icon-switch:before {
  @include flix-icon-switch;
}

.flix-icon-ticket-solid:before {
  @include flix-icon-ticket-solid;
}

.flix-icon-ticket-special-solid:before {
  @include flix-icon-ticket-special-solid;
}

.flix-icon-ticket-special:before {
  @include flix-icon-ticket-special;
}

.flix-icon-ticket:before {
  @include flix-icon-ticket;
}

.flix-icon-time-solid:before {
  @include flix-icon-time-solid;
}

.flix-icon-time:before {
  @include flix-icon-time;
}

.flix-icon-toilet-solid:before {
  @include flix-icon-toilet-solid;
}

.flix-icon-toilet:before {
  @include flix-icon-toilet;
}

.flix-icon-train-night-solid:before {
  @include flix-icon-train-night-solid;
}

.flix-icon-train-night:before {
  @include flix-icon-train-night;
}

.flix-icon-train-service-solid:before {
  @include flix-icon-train-service-solid;
}

.flix-icon-train-service:before {
  @include flix-icon-train-service;
}

.flix-icon-train-solid:before {
  @include flix-icon-train-solid;
}

.flix-icon-train-stop-solid:before {
  @include flix-icon-train-stop-solid;
}

.flix-icon-train-stop:before {
  @include flix-icon-train-stop;
}

.flix-icon-train-time-solid:before {
  @include flix-icon-train-time-solid;
}

.flix-icon-train-time:before {
  @include flix-icon-train-time;
}

.flix-icon-train-wagon-solid:before {
  @include flix-icon-train-wagon-solid;
}

.flix-icon-train:before {
  @include flix-icon-train;
}

.flix-icon-transfer-solid:before {
  @include flix-icon-transfer-solid;
}

.flix-icon-transfer:before {
  @include flix-icon-transfer;
}

.flix-icon-trip-solid:before {
  @include flix-icon-trip-solid;
}

.flix-icon-trip:before {
  @include flix-icon-trip;
}

.flix-icon-video-solid:before {
  @include flix-icon-video-solid;
}

.flix-icon-video:before {
  @include flix-icon-video;
}

.flix-icon-voucher-solid:before {
  @include flix-icon-voucher-solid;
}

.flix-icon-voucher:before {
  @include flix-icon-voucher;
}

.flix-icon-warning:before {
  @include flix-icon-warning;
}

.flix-icon-wheelchair-solid:before {
  @include flix-icon-wheelchair-solid;
}

.flix-icon-wheelchair:before {
  @include flix-icon-wheelchair;
}

.flix-icon-wifi-solid:before {
  @include flix-icon-wifi-solid;
}

.flix-icon-wifi:before {
  @include flix-icon-wifi;
}

.flix-icon-arrow-big-down:before {
  @include flix-icon-arrow-big-down;
}

.flix-icon-arrow-big-left:before {
  @include flix-icon-arrow-big-left;
}

.flix-icon-arrow-big-right:before {
  @include flix-icon-arrow-big-right;
}

.flix-icon-arrow-big-up:before {
  @include flix-icon-arrow-big-up;
}

.flix-icon-car-solid:before {
  @include flix-icon-car-solid;
}

.flix-icon-car:before {
  @include flix-icon-car;
}
