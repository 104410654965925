
// ------------------------------------
// GRID CLASSES
// ------------------------------------

@import '../config/all';
@import '../utils/all';


#{$config-class-prefix} {
  &grid {
    @include grid-row();

    &--align-bottom {
      align-items: flex-end;
    }

    &--align-top {
      align-items: flex-start;
    }

    &--align-center {
      justify-content: center;
    }

    &--align-left {
      justify-content: flex-start;
    }

    &--align-right {
      justify-content: flex-end;
    }
  }

  @each $size, $value in $breakpoints {
    // skips breakpoints for the smallest "xs" breakpoint
    @if index(map-keys($breakpoints), $size) == 1 {
      @for $i from 1 through grid-columns() {
        // grid column classes follow .flix-col-{$columns}-{$breakpoint} naming pattern
        &col-#{$i} {
          @include grid-col($i);
        }

        // margin push utility classes follow .flix-push-{$columns}-{$breakpoint} naming pattern
        &push-#{$i} {
          @include grid-push($i);
        }
      }
    } @else {
      @include on-bp($size) {
        @for $i from 1 through grid-columns() {
          // grid column classes follow .flix-col-{$columns}-{$breakpoint} naming pattern
          &col-#{$i}-#{$size} {
            @include grid-col($i);
          }

          // margin push utility classes follow .flix-push-{$columns}-{$breakpoint} naming pattern
          &push-#{$i}-#{$size} {
            @include grid-push($i);
          }
        }
      }
    }
  }

  // flexible columns that fill in the whole row
  &col {
    flex: 1;
    margin-right: grid-gutter();
  }
}

// removes right padding from the last columns
[class*=flix-col]:last-child,
#{$config-class-prefix}col--last {
  margin-right: 0;
}
