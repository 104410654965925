// ------------------------------------
// Input select (.[prefix-]select)
// ------------------------------------

@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {

  &select {
    // sass-lint:disable no-vendor-prefixes
    @include basic-input; //@todo create a separate styleset for selectboxes and remove the extend

    padding-right: 40px;
    outline: 0;
    background-color: transparent;
    background-image: svg-url-encode($image-svg-arrow-down);
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 14px 14px;

    &:hover,
    &:active,
    &:focus {
      outline: 0;
    }

    &:focus,
    &--active {
      border: 1px solid $input-active-border-color;
      outline: none;
    }

    &[disabled],
    &--disabled {
      opacity: .5;
    }

    // since select box is custom and has 2 background images we can't use icon mixins
    &--valid {
      background-image: svg-url-encode($image-svg-arrow-down), svg-url-encode($image-svg-valid);
      background-repeat: no-repeat;
      background-position: right 15px center, right 40px center;
      background-size: 14px 14px, 21px 14px;
    }

    &--error {
      border: 1px solid $input-error-border-color;
      background-image: svg-url-encode($image-svg-arrow-down), svg-url-encode($image-svg-error);
      background-repeat: no-repeat;
      background-position: right 15px center, right 40px center;
      background-size: 14px 14px, 5px 20px;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
