// ------------------------------------
// Spinner (.prefix-spinner)
// ------------------------------------

@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &spinner {
    display: inline-block;
    width: get-spacing(lg);
    height: get-spacing(lg);
    border: 4px solid $matcha-green-color;
    border-radius: 50%;
    border-left-color: $primary-brand-color;
    animation: spinner-rotate 1s infinite linear;
  }
  @include anim-rotate($anim-name: 'spinner-rotate')
}
