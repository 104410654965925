// ------------------------------------
// input utilities
// ------------------------------------
/// @access private
// ------------------------------------

@import '../config/all';
@import 'positioning'; // pulling in get-spacing mixin
@import 'assets-urls'; // pulling in svg-url-encode function

// ------------------------------------
// basic-input
// ------------------------------------
// defines basic input styles
// ------------------------------------

@mixin basic-input {

  @include font-primary-normal;
  position: relative;
  width: 100%;
  height: $input-height-mobile;
  padding: 0 get-spacing(xs);
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  background-color: $input-bg-color;
  color: $primary-content-color;
  line-height: $line-height-primary;
  text-overflow: ellipsis;
  // getting rid of default iOS webkit styles
  appearance: none;

  @include on-bp(xl) {
    height: $input-height-desktop;
  }

  &::placeholder {
    color: $input-placeholder-color;
    font-style: italic;
  }
}


// ------------------------------------
// input-icon-valid
// ------------------------------------
// adds checkmark icon background to the form input fields
// ------------------------------------

@mixin input-icon-valid($position: right 15px center) {
  background-image: svg-url-encode($image-svg-valid);
  background-repeat: no-repeat;
  background-position: $position;
  background-size: 21px 14px;
}


// ------------------------------------
// input-icon-error
// ------------------------------------
// adds icon background to the form input fields
// ------------------------------------

@mixin input-icon-error($position: right 15px center) {
  background-image: svg-url-encode($image-svg-error);
  background-repeat: no-repeat;
  background-position: $position;
  background-size: 5px 20px;
}
