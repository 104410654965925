// ----------------------------------------------
// Hoverable tooltip (.[prefix-]element-has-hint)
// powered by data attribute/pseudo elements CSS magic
// ----------------------------------------------


@import '../config/all';
@import '../utils/all';
@import 'tooltip';

#{$config-class-prefix} {
  &element-has-hint {
    position: relative;

    // Hides hint by default
    &::before,
    &::after {
      display: none;
    }

    &::before {
      @include tooltip-content();
      // hover hint should be smaller and adapt to content size
      width: auto;
      min-width: 150px;
      content: attr(data-hint);
    }

    // hint arrow
    &::after {
      @include tooltip-arrow();
      // since arrow and text container are pseudo-elements of .element-has-hint wrapper
      // their placement is a bit different from the normal hints
      left: 100%;
      transform: translateY(-50%) translateX(50%) rotate(45deg);
      content: '';
    }

    // Modifies hint appearance to represent error
    &--error {
      &::before,
      &::after {
        background: $tooltip-error-bg-color;
        color: $tooltip-error-font-color;
      }
    }

    // Makes hint to appear to the left
    &--left {
      &::before {
        right: 100%;
        left: auto;
        margin-right: get-spacing(xs);
        margin-left: 0;
      }

      &::after {
        right: 100%;
        left: auto;

        transform: translateY(-50%) translateX(-50%) rotate(-135deg);
      }
    }

    // Makes hint to appear on top
    &--top {
      &::before {
        top: auto;
        bottom: 100%;
        left: 50%;
        margin-bottom: get-spacing(xs);
        margin-left: 0;

        transform: translateY(0%) translateX(-50%);
      }

      &::after {
        top: auto;
        bottom: 100%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      }
    }

    // Makes hint to appear at the bottom
    &--bottom {
      &::before {
        top: 100%;
        left: 50%;
        margin-top: get-spacing(xs);
        margin-left: 0;

        transform: translateY(0%) translateX(-50%);
      }

      &::after {
        top: 100%;
        left: 50%;
        transform: translateY(50%) translateX(-50%) rotate(135deg);
      }
    }
  }

  // controls when hint appears
  &element-has-hint:hover,
  &element-has-hint:focus,
  &element-has-hint--active {
    &::before,
    &::after {
      display: block;
    }
  }
}
