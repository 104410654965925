// ----------------------------------------------
// Info box (.[prefix-]infobox)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';
// base icon mixins to get arrow icons
@import '../icons/base-mixins';
@import '../icons/icon-mixins';

// ------------------------------------
// style variables
// ------------------------------------
$infobox-background-color: $primary-bg-color;
$infobox-icon-size: 50px;
$infobox-icon-default-color: $link-color;

#{$config-class-prefix} {
  &infobox {
    display: flex;
    align-items: center;
    margin-bottom: get-spacing(xs);
    padding: get-spacing(xs);
    border-radius: $primary-border-radius;
    font-family: $font-family-primary;
    font-size: $font-size-primary;
    font-weight: $font-weight-normal;
    box-shadow: $primary-box-shadow;

    @include on-bp(sm) {
      padding: get-spacing(sm);
    }

    &::before {
      // @todo find a better way to put icons in here
      @include flix-icon-abstract; // solves icon rendering issues in some browsers and OS'
      @include flix-icon;
      @include flix-icon-large;
      @include flix-icon-attention;
      align-self: flex-start;
      margin-right: 15px;
      color: $infobox-icon-default-color;

      @include on-bp(sm) {
        top: 50%;
        left: get-spacing(sm);
      }
    }

    &--warning {
      &::before {
        color: $warning-color;
      }
    }

    &--danger {
      &::before {
        color: $fail-color;
      }
    }
  }
}
