// ------------------------------------
// Responsive visibility helpers
// ------------------------------------
@import '../config/all';
@import '../utils/all';

// mimics bootstrap 3 utility classes, meant to achieve the compatibility with old flixbus layout

@include on-bp(xs, 'only') {
  // @todo here and bellow class names without prefix are provided for backwards compatibility and should be deprecated in the next version
  #{$config-class-prefix}hidden-xs,
  .hidden-xs {
    display: none;
  }
}

@include on-bp(sm, 'only') {
  #{$config-class-prefix}hidden-sm,
  .hidden-sm {
    display: none;
  }
}

@include on-bp(md, 'only') {
  #{$config-class-prefix}hidden-md,
  .hidden-md {
    display: none;
  }
}

@include on-bp(lg, 'only') {
  #{$config-class-prefix}hidden-lg,
  .hidden-lg {
    display: none;
  }
}

@include on-bp(xl) {
  #{$config-class-prefix}hidden-xl,
  .hidden-xl {
    display: none;
  }
}
