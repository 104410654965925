// ----------------------------------------------
// Radio input (.[prefix-]radio)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &radio {
    position: absolute;
    top: 3px;
    width: 14px;
    height: 14px;
    // Remove the padding in IE 10.
    padding: 0;
    // Add the correct box sizing in IE 10.
    box-sizing: border-box;
  }

  &radio-label {
    display: block;
    padding-left: get-spacing(sm);
  }
}
