// ----------------------------------------------
// Progress (.[prefix-]progress)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$progress-item-spacing: 30px;
$progress-number-radius: 22px;
$progress-color: $plain-white-color;
$progress-active-color: $primary-brand-color;
$progress-bg-color: transparent;
$progress-active-bg-color: $plain-white-color;


#{$config-class-prefix} {
  &progress {
    display: flex;
    align-items: center;
    // align steps vertically and horizontally within the container
    justify-content: center;
    height: 40px;
    background: $progress-bg-color;
    list-style-type: none;

    &__item {
      position: relative;
      min-width: $progress-number-radius; // preserves spacing to hold the number element
      height: $progress-number-radius;
      border: 1px solid $progress-color;
      border-radius: $progress-number-radius;
      background: $progress-bg-color;
      color: $progress-color;

      // gives the spacing for siblings only
      & + & {
        margin-left: $progress-item-spacing;
      }

      // adds connecting line in between the items
      & + &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: (-$progress-item-spacing);
        width: $progress-item-spacing;
        border-top: 1px solid $progress-color;
        content: '';
      }

      &--active {
        background: $progress-active-bg-color;
        color: $progress-active-color;
      }
    }

    &__number {
      position: absolute;
      top: 50%;
      left: -1px;
      width: $progress-number-radius;
      height: $progress-number-radius;
      margin-top: -($progress-number-radius/2);
      border: 1px solid $progress-color;
      border-radius: 100%;
      font-weight: $font-weight-semibold;
      line-height: $progress-number-radius;
      text-align: center;
    }

    &__item--active {
      #{$config-class-prefix}progress__number {
        border-color: $progress-active-color;
      }
    }

    &__text {
      display: none;
      padding: 0 get-spacing(xs) 0 get-spacing(sm);
      font-weight: $font-weight-semibold;
      line-height: $progress-number-radius;

      @include on-bp(sm) {
        display: block;
      }
    }
  }
}
