// ----------------------------------------------
// Button arrow (.prefix-btn-arrow)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';
// base icon mixins to get arrow icons
@import '../icons/base-mixins';
@import '../icons/icon-mixins';

// ------------------------------------
// style variables
// ------------------------------------
$button-arrow-text-color: $primary-content-color;
$button-arrow-strong-text-color: $strong-highlight-color;
$button-arrow-font-size: 18px;
$button-arrow-font-weight: $font-weight-semibold;
$button-arrow-line-height: 24px;

#{$config-class-prefix} {

  &btn-arrow {
    display: inline-block;
    position: relative;
    min-height: 0;
    color: $button-arrow-text-color;
    font-family: $font-family-primary;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-line-height;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &::after {
      @include flix-icon-abstract;
      @include flix-icon;
      @include flix-icon-arrow-right;
    }

    &--back {
      &::after {
        content: '';
      }

      &::before {
        @include flix-icon-abstract;
        @include flix-icon;
        @include flix-icon-arrow-left;
        margin-left: 0;
      }
    }

    &--top {
      &::after {
        @include flix-icon-abstract;
        @include flix-icon;
        @include flix-icon-arrow-up;
      }
    }

    &--bottom {
      &::after {
        @include flix-icon-abstract;
        @include flix-icon;
        @include flix-icon-arrow-down;
      }
    }

    &--strong {
      color: $button-arrow-strong-text-color;

      &::before,
      &::after {
        color: $button-arrow-strong-text-color;
      }
    }
  }
}
