// ----------------------------------------------
// Button (.prefix-btn)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$button-vertical-padding: 6px;
$button-vertical-padding-mobile: 10px;
$button-font-size: 18px;
$button-font-weight: $font-weight-semibold;
$button-line-height: 24px;
$button-text-color: $primary-bg-color;
$button-primary-bg-color: $warning-color;
$button-primary-hover-bg-color: $cinnamon-orange-color;
$button-secondary-bg-color: $success-color;
$button-secondary-hover-bg-color: $cucumber-green-color;
$button-tertiary-bg-color: $primary-bg-color;
$button-tertiary-hover-bg-color: $cucumber-green-color;
$button-tertiary-text-color: $success-color;
$button-danger-bg-color: $fail-color;
$button-danger-hover-bg-color: $cherry-red-color;
$button-disabled-bg-color: $disabled-color;
$button-radius: $primary-border-radius;
$button-loading-spinner-size: 18px;
$button-spinner-color: rgba(255, 255, 255, 0.6);
$button-spinner-slice-color: rgba(255, 255, 255, 1);

#{$config-class-prefix} {
  &btn {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 145px;
    min-height: 36px;
    padding-top: $button-vertical-padding-mobile;
    padding-right: get-spacing(xs);
    padding-bottom: $button-vertical-padding-mobile;
    padding-left: get-spacing(xs);
    transition: background 250ms linear;
    border: 0;
    border-radius: $button-radius;
    font-family: $font-family-primary;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-line-height;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    // getting rid of default iOS webkit styles
    appearance: none;

    @include on-bp(sm) {
      width: auto;
    }

    @include on-bp(xl) {
      padding-top: $button-vertical-padding;
      padding-bottom: $button-vertical-padding;
    }

    &--block {
      display: block;
      width: 100%;
    }

    &--primary {
      border: 0;
      background: $button-primary-bg-color;
      color: $button-text-color;

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background: $button-primary-hover-bg-color;
        color: $button-text-color;
        text-decoration: none;
      }
    }

    &--secondary {
      border: 0;
      background: $button-secondary-bg-color;
      color: $button-text-color;

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background: $button-secondary-hover-bg-color;
        color: $button-text-color;
        text-decoration: none;
      }
    }

    &--danger {
      border: 0;
      background: $button-danger-bg-color;
      color: $button-text-color;

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background: $button-danger-hover-bg-color;
        color: $button-text-color;
        text-decoration: none;
      }
    }

    &--tertiary {
      // compensating the border width to preserve the same button height
      padding-top: $button-vertical-padding-mobile - 1;
      padding-bottom: $button-vertical-padding-mobile - 1;
      border: 1px solid $button-tertiary-text-color;
      background: $button-tertiary-bg-color;
      color: $button-tertiary-text-color;

      @include on-bp(xl) {
        // compensating the border width to preserve the same button height
        padding-top: $button-vertical-padding - 1;
        padding-bottom: $button-vertical-padding - 1;
      }

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background-color: $button-tertiary-hover-bg-color;
        color: $button-text-color;
        text-decoration: none;
      }
    }

    &--disabled {
      border: 0;
      background: $button-disabled-bg-color;
      color: $button-text-color;

      &:hover {
        background: $button-disabled-bg-color;
        color: $button-text-color;
        text-decoration: none;
      }
    }

    &--loading {
      display: inline-flex; // the best way to have spinner and text aligned in the middle
      align-items: center;
      justify-content: center;

      // animated rounded spinner
      &::before {
        display: inline-block;
        width: $button-loading-spinner-size;
        height: $button-loading-spinner-size;
        margin-right: 7px;
        border: 1px solid $button-spinner-color;
        border-radius: 50%;
        border-left-color: $button-spinner-slice-color;
        content: '';
        animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
      }
      @include anim-grow-fade(
        $anim-name: 'btn-spinner-grow',
        $to-width: $button-loading-spinner-size,
        $to-height: $button-loading-spinner-size
      );
      @include anim-rotate(
        $anim-name: 'btn-spinner-rotate'
      )
    }

    &--has-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      &::before {
        margin-left: 0;
      }
    }
  }

  &btn + &btn {
    margin-top: get-spacing(xs);

    @include on-bp(sm) {
      margin-top: 0;
      margin-left: get-spacing(xs);
    }
  }

}
