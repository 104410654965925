// ----------------------------------------------
// Range input (.[prefix-]range-slider & [type='range'])
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$input-range-track-color: $strong-highlight-color !default;
$input-range-thumb-color: $primary-bg-color !default;

$input-range-thumb-radius: 11px !default;
$input-range-thumb-height: 22px !default;
$input-range-thumb-width: 22px !default;
$input-range-thumb-border-width: 1px !default;
$input-range-thumb-border-color: $primary-line-color !default;

$input-range-track-width: 100% !default;
$input-range-track-height: 5px !default;
$input-range-track-border-width: 0px !default;
$input-range-track-border-color: $strong-highlight-color !default;

$input-range-track-radius: 5px !default;
$input-range-contrast: 5% !default;


// Based on:
// Github: https://github.com/darlanrod/input-range-sass
// Author: Darlan Rod https://github.com/darlanrod

@mixin track {
  width: $input-range-track-width;
  height: $input-range-track-height;
  transition: all .2s ease;
  cursor: pointer;
}

@mixin thumb {
  width: $input-range-thumb-width;
  height: $input-range-thumb-height;
  border: $input-range-thumb-border-width solid $input-range-thumb-border-color;
  border-radius: $input-range-thumb-radius;
  background: $input-range-thumb-color;
  cursor: pointer;
}

#{$config-class-prefix} {

  &range-slider {
    // sass-lint:disable no-vendor-prefixes
    position: relative;
    height: $input-range-thumb-height;
    text-align: center;

    [type='range'] {
      position: absolute;
      top: 0;
      left: 0;
      width: $input-range-track-width;
      height: $input-range-thumb-height;
      margin: 0;
      padding: 0;
      outline: none;
      overflow: hidden;
      appearance: none;
      pointer-events: none;

      &::-webkit-slider-runnable-track {
        @include track;
        border: $input-range-track-border-width solid $input-range-track-border-color;
        border-radius: $input-range-track-radius;
        background: $input-range-track-color;
      }

      &::-webkit-slider-thumb {
        @include thumb;
        position: relative;
        margin-top: ((-$input-range-track-border-width * 2 + $input-range-track-height) / 2) - ($input-range-thumb-height / 2);
        outline: 0;
        z-index: 1;
        -webkit-appearance: none;
        pointer-events: all;
      }

      &::-moz-range-track {
        @include track;
        position: relative;
        border: $input-range-track-border-width solid $input-range-track-border-color;
        border-radius: $input-range-track-radius;
        background: $input-range-track-color;
        z-index: -1;
      }

      &:last-of-type::-moz-range-track {
        border: 0;
        background: none transparent;
        appearance: none;
      }

      &::-moz-range-thumb {
        @include thumb;
        position: relative;
        z-index: 10;
        appearance: none;
        pointer-events: all;
      }

      &::-moz-focus-outer {
        border: 0;
      }

      &::-ms-track {
        @include track;
        border-width: ($input-range-thumb-height / 2) 0;
        border-color: transparent;
        background: transparent;
        color: transparent;
      }

      &::-ms-fill-lower {
        border: $input-range-track-border-width solid $input-range-track-border-color;
        border-radius: $input-range-track-radius * 2;
        background: darken($input-range-track-color, $input-range-contrast);
      }

      &::-ms-fill-upper {
        border: $input-range-track-border-width solid $input-range-track-border-color;
        border-radius: $input-range-track-radius * 2;
        background: $input-range-track-color;
      }

      &::-ms-thumb {
        @include thumb;
        margin-top: 0;
      }
    }


    [type='range']:focus {
      outline: 0;

      &::-ms-fill-lower {
        background: $input-range-track-color;
      }

      &::-ms-fill-upper,
      &::-webkit-slider-runnable-track {
        background: lighten($input-range-track-color, $input-range-contrast);
      }
    }
  }
}
