// ------------------------------------
// PAGE LAYOUTS
// ------------------------------------

.main-wrapper {
  position: relative;
  min-height: 100%;
  margin-top: 0;
  padding-top: get-spacing(md);
  padding-bottom: 0;
  background: $primary-bg-color;

  @include on-bp(lg) {
    padding-top: get-spacing(md);
  }
}

// Page grid
// ----------------------------------------------

.page {
  min-width: 320px;
  max-width: 1200px;
  margin: 0 auto get-spacing(lg);
  @include grid-row();

  @include on-bp(lg) {
    padding-bottom: get-spacing(lg);
  }
}

.page__content {
  @include grid-col(12, $is-last: true);

  @include on-bp(md) {
    @include grid-col(10, $is-last: true);
  }
}

.page__sidebar {
  @include grid-col(12);

  @include on-bp(md) {
    @include grid-col(2);
  }
}
