// ----------------------------------------------
// Connection (.[prefix-]connection)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$connection-time-column-width: 38px;
$connection-start-end-station-marker-width: 9px;
$connection-station-marker-width: 5px;

#{$config-class-prefix} {

  &connection {
    margin-bottom: get-spacing(sm);

    &__title {
      margin-bottom: get-spacing(xs) / 2;

      & span {
        font-weight: $font-weight-bold;
      }
    }

    &__row {
      display: flex;

      &--is-hidden {
        display: none;
      }
    }


    &__time {
      flex: 0 0 $connection-time-column-width;
      align-self: flex-start;
      margin-right: get-spacing(xs) - 2px; // optical alignment;

      &--departure {
        font-weight: $font-weight-bold;
      }
    }

    &__live-time, 
    &__schedule-time {
      flex: 0 0 $connection-time-column-width * 2 + get-spacing(xs) + 4px;
      align-self: flex-start;
      text-align: right;
    }

    &__live-time {

      &--danger,
      &--warning {
        display: inline-block;
        width: $connection-time-column-width;
      }

      &--warning {
        color: $warning-color;
      }

      &--danger {
        color: $fail-color;
      }

      &--old {
        display: inline-block;
        width: $connection-time-column-width;
        margin-right: get-spacing(xs);
        color: $space-grey-color;
        text-decoration: line-through;
      }
    }

    &__station {
      position: relative;
      flex-grow: 100;
      align-self: flex-start;
      padding-left: get-spacing(xs) + $connection-start-end-station-marker-width / 2 + 2px; // marker has border
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &-info,
      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-info--multiline,
      &-name--multiline {
        white-space: normal;
      }

      &::after {
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: floor($connection-start-end-station-marker-width / 2);
        width: 1px;
        background: $primary-icon-color;
        content: '';
        z-index: 1;
      }

      &--departure::after {
        top: 10px;
      }


      &--arrival::after {
        bottom: auto;
        height: $line-height-primary / 2;
      }

      &::before {
        position: absolute;
        top: $line-height-primary / 2;
        left: floor($connection-start-end-station-marker-width / 2);
        width: $connection-station-marker-width;
        height: 1px;
        background: $primary-icon-color;
        content: '';
        vertical-align: middle;
        z-index: 2;
      }

      &--departure::before,
      &--arrival::before {
        display: inline-block;
        width: $connection-start-end-station-marker-width;
        height: $connection-start-end-station-marker-width;
        margin-top: - ceil($connection-start-end-station-marker-width / 2);
        border: 1px solid $primary-icon-color;
        border-radius: 50%;
        background: $primary-icon-color;
        content: '';
      }

      &--departure::before {
        left: 0;
        background: $plain-white-color;
      }

      &--arrival::before {
        left: 0;
      }

      &-info {
        color: $boulder-grey-color;
      }

    }

    &--live &__row {
      position: relative;
      align-items: center;
      @include on-bp(md) {
        padding-bottom: get-spacing(xs);
      }
    }

    &--live &__station {
      position: static;
    }

    &--live &__station--departure .flix-connection__station-name,
    &--live &__station--arrival .flix-connection__station-name {
      font-weight: $font-weight-bold;
    }

    &--live &__row:first-child {
      display: none;
    }

  }

}
