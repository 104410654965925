// ----------------------------------------------
// Checkbox (.[prefix-]checkbox)
// ----------------------------------------------

@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &checkbox {
    position: absolute;
    top: 2px;
    width: 14px;
    height: 14px;
    // 1. Add the correct box sizing in IE 10.
    // 2. Remove the padding in IE 10.
    padding: 0;
    box-sizing: border-box;
  }

  &checkbox-label {
    display: block;
    padding-left: get-spacing(sm);
  }
}
