// ------------------------------------
// CONFIGURATION  VARIABLES
// ------------------------------------

$config-prefix: 'flix-';
$config-class-prefix: '.#{$config-prefix}';
// allows setting of max breakpoint value to achieve having "mobile only" or "mobile/tablet only" styles
$config-breakpoint-limit: false !default; // no limit is set by default
// toggles flix-header--expand-{bp} modifier classes
// produces 1000 more lines of CSS in the end, turn of if resulting CSS file size matters
$header-expand-feature: false;
// stores list of imported scss partials allowing implementation of import once functionality (see exports() mixin for more details)
$modules: () !default;

// -----------------------------------
// ASSETS MANAGEMENT
// -----------------------------------

/// Base path for assets (fonts, images...),
/// should not include trailing slash
/// if you have the library as npm package, put the path to your node_modules folder in here (PATH_TO_NODE_MODULES/flixUi/assets)
/// @access public
/// @type String
$asset-base-path: '..' !default;

// ------------------------------------
// COLOR VARIABLES
// ------------------------------------

// Utility colors

$primary-brand-color: $uranium-green-color;
$secondary-brand-color: $honey-orange-color;

$primary-bg-color: $plain-white-color;
$neutral-color: $curacao-blue-color;
$success-color: $uranium-green-color;
$warning-color: $honey-orange-color;
$fail-color: $strawberry-red-color;
$disabled-color: $silver-grey-color;

$strong-highlight-color: $honey-orange-color;

// Font colors

$primary-content-color: $obsidian-black-color;
$secondary-content-color: $boulder-grey-color;
$header-font-color: $primary-content-color;
$link-color: $curacao-blue-color;

// Border colors

$primary-line-color: $mercury-grey-color;

// Icon colors

$primary-icon-color: $space-grey-color;

// Shadows

$box-shadow-color: rgba(0, 0, 0, .15);
$box-shadow-subtle-color: rgba(0, 0, 0, .1);
$primary-box-shadow: 0 0 15px $box-shadow-color;

// Overlays

$overlay-color: rgba(0, 0, 0, .15);


// ------------------------------------
// GEOMETRY VARIABLES
// ------------------------------------

$primary-border-radius: 5px;

// Spacing

$spacing-xs: 15px; // Extra small
$spacing-sm: 30px; // Small
$spacing-md: 60px; // Medium
$spacing-lg: 90px; // Large

// Spacing map

$s-map: (
  xs: $spacing-xs,
  sm: $spacing-sm,
  md: $spacing-md,
  lg: $spacing-lg
);


// ------------------------------------
// GRID VARIABLES
// ------------------------------------

$grid: (
  'columns': 12,
  'gutter': 15px
);

// ------------------------------------
// SVG ICONS
// ------------------------------------

$image-svg-valid: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8"><title>export board 2</title><path d="M4.66,7.57a.75.75,0,0,1-.53-.22l-3-3A.75.75,0,0,1,2.23,3.33L4.66,5.76,9.77.65a.75.75,0,0,1,1.06,1.06L5.19,7.35A.75.75,0,0,1,4.66,7.57Z" style="fill:#73d700"/></svg>';
$image-svg-error: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 12"><title>export board 1</title><path d="M1.5,7.86a.75.75,0,0,1-.75-.75V1.83a.75.75,0,0,1,1.5,0V7.11A.75.75,0,0,1,1.5,7.86Z" style="fill:#ff7300"/><circle cx="1.5" cy="9.92" r="1" style="fill:#ff7300"/></svg>';
$image-svg-arrow-down: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.87 4.92"><title>flix-circle-arr</title><path d="M4.46,4.77a1.74,1.74,0,0,1-1.24-.51L.4,1.43A.75.75,0,0,1,1.46.37L4.28,3.2a.25.25,0,0,0,.35,0L7.47.37A.75.75,0,0,1,8.53,1.43L5.7,4.26A1.74,1.74,0,0,1,4.46,4.77Z" style="fill:#8b8b8b"/></svg>';


// ------------------------------------
// Typography variables
// ------------------------------------

$font-family-primary: 'Roboto Condensed', 'Arial', sans-serif;
$font-weight-normal: 300;
$font-weight-semibold: 400;
$font-weight-bold: 700;
$font-size-primary: 16px;
$font-size-small: 14px;
$line-height-primary: 1.375em;


// ------------------------------------
// Shared form input variables
// ------------------------------------

$input-height-mobile: 44px;
$input-height-desktop: 36px;
$input-border-radius: $primary-border-radius;
$input-bg-color: $plain-white-color;
$input-border-color: $silver-grey-color;
$input-active-border-color: $boulder-grey-color;
$input-error-border-color: $fail-color;
$input-placeholder-color: $secondary-content-color;
