// ------------------------------------
// font utilities
// ------------------------------------
/// @access private
// ------------------------------------

@import '../config/all';

// ------------------------------------
// font-primary-normal
// ------------------------------------
// Sets font family, size, style, weight
// to be the one defined in the styleguide
// ------------------------------------

@mixin font-primary-normal {
  font-family: $font-family-primary;
  font-size: $font-size-primary;
  font-weight: $font-weight-normal;
}
